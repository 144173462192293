import {dayjs} from "./dayjsWrapper";
import {User, VoiceType} from "@/models";
import {Dayjs} from "dayjs";

/* Unused
export const EMAIL_REGEX = /^[^@\s]+@\w[\w-]*\w(\.\w[\w-]*\w)+$/;
export const isValidEmail = (email: string) => EMAIL_REGEX.test(email);

export const blobToBase64 = (blob: Blob) => {
    if (!blob) return;
    return new Promise<string>((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as any);
        reader.readAsDataURL(blob);
    });
};

export const base64toBlob = (b64Data: string, contentType = "") => {
    if (!b64Data.length) return null;
    const [header, data] = b64Data.split(",");
    const buffer = Buffer.from(data, "base64");
    if (!contentType) contentType = (/(?:[^:]*:)([^;]*)(?:;)/.exec(header) || [""]).pop()!;
    return new Blob([buffer], {type: contentType});
};


// Not a real uuid :P just uses `Math.random()`
export const uuid = () => {
    return Math.random().toString(36).substring(2, 10);
};

export const getTimeUntil = (target: string) => {
    const now = dayjs();
    const days = Math.abs(now.diff(target, "days"));
    if (days > 0) {
        return `${days} day${days > 1 ? "s" : ""}`;
    }

    const hours = Math.abs(now.diff(target, "hours"));
    if (hours > 0) {
        return `${hours} hour${hours > 1 ? "s" : ""}`;
    }

    const mins = Math.abs(now.diff(target, "minutes"));
    if (mins > 0) {
        return `${mins} minute${mins > 1 ? "s" : ""}`;
    }

    return "less than a minute";
};

export const isGuid = (id: string) =>
    /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(id);

export const removeReplaceAfterLast = (str: string, val: string, replaceVal?: string) => {
    const index = str.lastIndexOf(val);
    if (index < 0) return str;
    return str.substring(0, index + 1) + (replaceVal ? replaceVal : "");
};


export const capitalizeString = (str: string) => {
    return str.charAt(0).toLocaleUpperCase() + str.slice(1, str.length);
};

export const getShippingClassificationString = (sc: ShippingClassification) => {
    switch (sc) {
        case ShippingClassification.economy:
            return "economy";
        case ShippingClassification.standard:
            return "standard";
        case ShippingClassification.express:
            return "express";
        case ShippingClassification.priority:
            return "priority";
    }
};

export function queryCompare(obj1: any, obj2: any) {
    if (typeof obj1 !== "object" || typeof obj2 !== "object") return false;
    for (const key in obj1) {
        if (obj1[key] !== obj2[key]) return false;
    }
    return true;
}

export const roundUpOneDp = (num: string | number | undefined) => {
    const n = Number(num);
    if (isNaN(n)) return 0;
    return Math.ceil(n * 10) / 10;
};

*/

export const getCardNumberString = (last4?: string) => {
    if (!last4) return "No saved card";
    return `•••• •••• •••• ${last4}`;
};

export const getCardExpiryDate = ({
    cardExpiryMonth,
    cardExpiryYear,
    format,
}: {
    cardExpiryMonth: number;
    cardExpiryYear: number;
    format?: string;
}) => {
    return dayjs()
        .month(cardExpiryMonth - 1)
        .year(cardExpiryYear)
        .local()
        .format(format || "MM/YYYY");
};

export const getFormattedDate = (val: string | Dayjs) => {
    let date = val;
    if (typeof date === "string") date = dayjs(val + 'Z');
    return date.local().format("DD MMM, YYYY");
};

export function buildQueryString(query: Record<string, any>) {
    const keys = Object.keys(query);
    if (keys.length === 0) return "";
    return (
        "?" +
        keys
            .filter((k) => query[k] != null)
            .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(query[k])}`)
            .join("&")
    );
}

export const clamp = (val: number, min: number, max: number) =>
    val > max ? max : val < min ? min : val;

export const formatPhNum = (phNumber: string | undefined) => {
    if (!phNumber) return "";
    return phNumber.replace(/[\s-()]+/g, "");
};

export const isValidPhNumber = (phNum: string) =>
    /^\+?\d{1,4}?[-.\s]?((\(?\d{1,3}?\)?[-.\s]?)?(\d{1,4}[-.\s]?)*\d{1,4}|\d{10})$/.test(phNum);

export const isValidDate = (dateString: string) => {
        const regEx = /^\d{4}-\d{2}-\d{2}$/;
        if(!dateString.match(regEx)) return false;  // Invalid format
        const d = new Date(dateString);
        if(Number.isNaN(d.getTime())) return false; // Invalid date
        return d.toISOString().slice(0,10) === dateString; // Check for valid value
    };
    
export const isInThePast = (dateString: string | number | Date) => {
        const today = new Date();
        const inputDate = new Date(dateString);
        return inputDate < today;
    };

export const parseJwt = (token: string) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
};

export const userHasCard = (user: User | undefined) => {
    if (!user) return false;
    return (
        user.cardBrand &&
        user.cardLast4 &&
        user.cardExpiryMonth != null &&
        user.cardExpiryYear != null
    );
};

export const getVoiceTypeString = (vt: VoiceType) : string => {
    switch (vt) {
        case VoiceType.GenericMale:
            return "Sir Richard (Male Voice)";
        case VoiceType.GenericFemale:
            return "Fatima (Female Voice)";
        case VoiceType.Portuguese:
            return "Bruno (Portuguese Voice)"
        case VoiceType.Custom:
            return "Custom Voice";
        default:
            return "Select a voice";
    }
};

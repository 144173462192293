import { DiscountCodeDetails, GetPageParams, PaginatedList } from "@/models";
import { api } from "./api";
import { buildQueryString } from "@/utils/dataUtils";

interface PayoutAccountParams {
    individualBusinessType: boolean;
    firstName: string;
    lastName: string;
    companyName: string;
    phoneNumber: string;
    country: string;
}

interface PayoutAccountResponse {
    data: {
        created: number;
        expires_at: number;
        object: string;
        url: string;
        message: string;
        status: number;
        succeeded: boolean;
    },
    message: string;
}

export interface BookRoyalty {
    id: string;
    webStoryId: string;
    storyTitle: string;
    topLevelCategory: string;
    slug: string;
    totalSales: number;
    lifetimeRoyalty: number;
    unclaimedRoyalty: number;
    amazonUrl: string;
}

export enum checkStripeAccountEnum {
    DoesNotExist,
    OnboardingIncomplete,
    Onboarded
}

enum checkStripeAccountResponseStatusEnum {
    Success,
    Unauthorized,
    NotFound,
    BadRequest,
    ServerError,
    Forbidden,
    PaymentRequired
}


interface AccounLinkResponse {
    created: number;
    object: string;
    url: string;
}

interface StripeAccountSetupResponse {
    created: number;
    expires_at: number;
    object: string;
    url: string;
}

export const paymentApi = api.injectEndpoints({
    endpoints: (builder) => ({
        addCard: builder.mutation<string, void>({
            query: () => ({
                url: "Payment/AddCard",
                method: "POST",
            }),
            transformResponse: (response: { data: string }) => response.data,
        }),
        syncCards: builder.mutation<void, void>({
            query: () => ({
                url: "Payment/SyncCards",
                method: "POST",
            }),
            invalidatesTags: ["User"],
        }),
        getShippingPricing: builder.query<{ [key: string]: number }, number>({
            query: (quantity) => ({
                url: `Payment/GetShippingPricing/${quantity}`,
            }),
            transformResponse: (response: { data: any }) => response.data,
        }),
        getDiscountCodeDetails: builder.query<DiscountCodeDetails, string>({
            query: (code) => ({
                url: `Payment/DiscountCodeDetails?code=${code}`,
            }),
            transformResponse: (response: { data: DiscountCodeDetails }) => response.data,
        }),
        setupPayoutAccount: builder.mutation<PayoutAccountResponse, PayoutAccountParams>({
            query: (params) => ({
                url: "Payment/PayoutAccount",
                method: "POST",
                body: params,
            }),
            invalidatesTags: ["User"],
        }),
        getAllAmazonBooksRevenue: builder.query<PaginatedList<BookRoyalty>, GetPageParams>({
            query: (params) => ({
                url: `Amazon/AllAmazonBooksRevenue${buildQueryString({
                    ...params,
                    pageSize: params.pageSize || 10,
                  })}`,
            }),
            transformResponse: (response: { data: PaginatedList<BookRoyalty> }) => response.data,
        }),
        checkStripeConnectAccount: builder.query<checkStripeAccountEnum, void>({
            query: () => ({
                url: "Payment/CheckStripeConnectAccount",
            }),
            transformResponse: (response: { data: checkStripeAccountEnum }) => response.data,
        }),
        getAccountLink: builder.query<AccounLinkResponse, void>({
            query: () => ({
                url: "Payment/AccountLink",
            }),
            transformResponse: (response: { data: AccounLinkResponse }) => response.data,
        }),
        withdrawFunds: builder.mutation<void, void>({
            query: () => ({
                url: "Payment/WithdrawAmazonBalance",
                method: "POST",
            }),
            invalidatesTags: ["User"],
        }),
        stripeAccountUpdate: builder.mutation<void, void>({
            query: () => ({
                url: "Payment/AccountUpdate",
                method: "PUT",
            }),
            invalidatesTags: ["User"],
        }),
        stripeAccountSetupLink: builder.query<StripeAccountSetupResponse, void>({
            query: () => ({
                url: "Payment/SetupLink",
            }),
            transformResponse: (response: { data: StripeAccountSetupResponse }) => response.data,
        }),
    }),
});

export const {
    useAddCardMutation,
    useSyncCardsMutation,
    useSetupPayoutAccountMutation,
    useGetAllAmazonBooksRevenueQuery,
    useCheckStripeConnectAccountQuery,
    useGetAccountLinkQuery,
    useWithdrawFundsMutation,
    useStripeAccountUpdateMutation,
    useStripeAccountSetupLinkQuery,
    // useGetShippingPricingQuery,
    // useLazyGetDiscountCodeDetailsQuery,
} = paymentApi;

import { AnyFn } from "./modal";
import Link from "next/link";

export default function StoryTransitionModal({ resolve, reject }: { resolve: AnyFn; reject: AnyFn }) {
  return (
    <div className="text-center flex flex-col gap-5">
      <p className="font-semibold font-aleo text-2xl md:text-3xl">
        Transition to Story.com
      </p>

      <p>
        We are transitioning to Story.com. Please sign up ombackup
        story.com to continue using our
        services. Learn more <Link href="/blogs/story-transition" target="_blank" className="underline">here</Link>
      </p>

      <div className="flex flex-wrap justify-center gap-y-3 gap-x-5">
        <button onClick={resolve} className="btn-primary">
          Sign Up on Story.com
        </button>
      </div>
    </div>
  );
}

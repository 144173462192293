import { useEffect, useState } from "react";
import { useResendEmailVerificationMutation } from "../../store/apis/user-api";
import { AnyFn } from "./modal";
import { useLogEvent } from "../../utils/analytics";

export default function VerifyEmailModal({
    reject,
    title,
}: {
    resolve: AnyFn;
    reject: AnyFn;
    title?: string;
}) {
    const [resendVerification] = useResendEmailVerificationMutation();
    const logEvent = useLogEvent();

    const [resending, setResending] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    useEffect(() => {
        logEvent("verify_email_modal_viewed");
    }, []);

    const resendVerificationEmail = async () => {
        if (resending || emailSent) return;
        setResending(true);
        const currentPath = window.location.pathname;
        try {
            await resendVerification({ redirectUrl: currentPath }).unwrap();
            logEvent("email_verification_resent");
            setEmailSent(true);
        } catch (e: any) {
            // Handle error
        } finally {
            setResending(false);
        }
    };

    return (
        <div className="text-center flex flex-col gap-5">
            <p className="font-semibold font-aleo text-2xl md:text-3xl">
                {title || "It looks like you haven't verified your email yet"}
            </p>

            <div className="flex flex-col gap-3">
                <p>
                    To verify your email, click the verify link in the email we sent you when you
                    signed up.
                </p>
                <p>
                    Be sure to check your spam and updates folders. If it has gone to one of these
                    folders be sure to your main inbox so you can easily see future emails.
                </p>
                <p>
                    If you are unable to find your verification email click the resend button below
                    and we will send you another one.
                </p>
            </div>

            <div className="flex flex-wrap justify-center gap-y-3 gap-x-5">
                <button onClick={reject} className="btn-error">
                    Close
                </button>
                <button
                    className="btn-primary"
                    onClick={resendVerificationEmail}
                    disabled={resending || emailSent}>
                    {emailSent ? "Sent" : `Resend${resending ? "ing" : ""}`}
                </button>
            </div>
        </div>
    );
}

import {
  BookOrder,
  ContactUsFormParams,
  CreditSpendType,
  GetPageParams,
  PaginatedList,
  TinyUser,
  User,
  kycStatus
} from "@/models";
import { api } from "./api";
import { TabValue } from "@/components/dialogs/token-dialog";
import { Transactions } from "./types";
import { buildQueryString } from "@/utils/dataUtils";
export const DEFAULT_BOOK_ORDER_PAGE_SIZE = 5;

export type EmailPreferences = {
  amazonFailedEmail: boolean;
  amazonProcessingEmail: boolean;
  amazonPublishEmail: boolean;
  amazonRequestedEmail: boolean;
  bookOrderFailedEmail: boolean;
  bookOrderedEmail: boolean;
  bookOrderShippedEmail: boolean;
  lowTokensEmail: boolean;
  tokensPurchasedEmail: boolean;
  tokensSpentEmail: boolean;
  reportConfirmationEmail: boolean;
  publishedStoryDeletedEmail: boolean;
  storyPublishedEmail: boolean;
  newCommentOnStoryEmail: boolean;
};

export enum CreditPurchaseType {
  Gift, // not used
  Teaser, // 15 dollars
  Leaflet, // 30 dollars
  Anthology, // 50 dollars
  Library, // 150 dollars
  Supplement // insufficient funds
}

type TokenBalanceResponseType = {
  totalTokens: number;
  earliestExpiryPurchase: {
    id: string;
    creditPurchaseType: CreditPurchaseType;
    amount: number;
    creditsIssued: number;
    creditsRemaining: number;
    dateIssued: string;
    expiryDate: string;
    userId: string;
  }
}

type ResponseUpdateKYC = {
  data: User;
  succeeded: boolean;
  message: string;
  status: kycStatus;
}
type UpdateKYCParams = {
  Address: string;
  Country: string;
  PhotoId: File;
  PhotoIDType: string;
  PhotoIDNumber: string;
}

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<User, void>({
      query: () => ({
        url: "User",
      }),
      providesTags: ["User"],
      transformResponse: (response: { data: User }) => response.data,
    }),
    registerUser: builder.mutation<
      User,
      { name: string; email: string; verificationRequired: boolean }
    >({
      query: ({ name, email, verificationRequired }) => ({
        url: "User/Register",
        method: "POST",
        body: {
          name,
          email,
          verificationRequired,
        },
      }),
      transformResponse: (response: { data: User }) => response.data,
    }),
    updateNotificationPreferences: builder.mutation<
      any,
      EmailPreferences
    >({
      query: (emailPrefs) => ({
        url: "User/EmailNotificationPreferences",
        method: "PUT",
        body: emailPrefs,
      }),
      invalidatesTags: ["User"],
    }),
    removeCard: builder.mutation<void, void>({
      query: () => ({
        url: "Payment/RemoveCard",
        method: "POST",
      }),
      invalidatesTags: ["User"], // Assuming removing a card might change user data
    }),
    submitContactUsForm: builder.mutation<any, ContactUsFormParams>({
      query: (body) => ({
        url: "User/ContactUs",
        method: "POST",
        body,
      }),
    }),
    resendEmailVerification: builder.mutation<any, { redirectUrl?: string }>({
      query: ({ redirectUrl } = {}) => ({
        url: "User/RequestVerification",
        method: "POST",
        params: { redirectUrl: redirectUrl || null },
      }),
    }),
    verifyEmail: builder.mutation<any, string>({
      query: (verificationId: string) => ({
        url: `User/VerifyEmail`,
        method: "POST",
        body: { verificationId },
      }),
      invalidatesTags: ["User"],
    }),
    getBookOrders: builder.query<PaginatedList<BookOrder>, GetPageParams>({
      query: (params) => ({
        url: `User/BookOrders${buildQueryString({
          ...params,
          pageSize: params.pageSize || DEFAULT_BOOK_ORDER_PAGE_SIZE,
        })}`,
      }),
      providesTags: ["BookOrders"],
      transformResponse: (response: { data: PaginatedList<BookOrder> }) => response.data,
    }),
    editProfileDetails: builder.mutation<void, {
      profileName: string;
      name: string;
      lastName: string | null;
      phoneNumber: string | null;
      dateOfBirth: string | null;
      bio: string | null;
      profilePicture: File | null;
    }>({
      query: (data) => {
        const formData = new FormData();

        Object.keys(data).forEach((key) => {
          const value = data[key as keyof typeof data];
          if (value === null || value === "") {
            formData.append(key, "");
          } else if (value instanceof File) {
            formData.append(key, value);
          } else {
            formData.append(key, value.toString());
          }
        });

        return {
          url: "User/Details",
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["User"],
    }),
    deleteUser: builder.mutation<void, void>({
      query: () => ({
        url: "User/DeleteAccount",
        method: "DELETE",
      }),
      invalidatesTags: ["User", "UserLib", "Lib", "Story", "UserStory"],
    }),
    purchaseCredits: builder.mutation<{ succeeded: boolean ; nextAction: any ; requiresAction: boolean ; clientSecret: string }, { creditPurchaseType: CreditPurchaseType, creditSpendType?: CreditSpendType }>({
      query: ({ creditPurchaseType, creditSpendType }) => ({
        url: "User/PurchaseCredits",
        method: "POST",
        body: {
          creditPurchaseType,
          creditSpendType,
        },
      }),
      invalidatesTags: ["User"],
      transformResponse: (response: { data: { succeeded: boolean ; nextAction: any ; requiresAction: boolean ; clientSecret: string } }) => response.data,
    }),
    verifyPurchaseCredits: builder.mutation<{ succeeded: boolean }, { creditPurchaseType: CreditPurchaseType, paymentIntentId: string ; amount: number }>({
      query: ({ creditPurchaseType, paymentIntentId, amount}) => ({
        url: "Payment/VerifyPurchaseCredits",
        method: "POST",
        body: {
          creditPurchaseType,
          paymentIntentId,
          amount,
        },
      }),
      invalidatesTags: ["User"],
    }),
    getTokenBalance: builder.query<TokenBalanceResponseType, any>({
      query: () => ({
        url: `User/TokenBalance`
      }),
      providesTags: ["User", "UserLib", "Lib", "Story", "UserStory"],
      transformResponse: (response: { data: TokenBalanceResponseType }) => response.data,
    }),
    getTransactions: builder.query<any, { currentPage: number, pageSize: number }>({
      query: (params) => ({
        url: `User/Transactions`,
        params,
      }),
      providesTags: ["User"],
      transformResponse: (response: { data: Transactions }) => response.data,
    }),
    getUserProfileInfo: builder.query({
      query: ({ profileName }) => ({
        url: `User/${profileName}`
      }),
      transformResponse: (response: { data: TinyUser }) => response.data,
    }),
    getUserPurchase: builder.query<any, { id: string | undefined, itemTypes: CreditSpendType[] }>({
      query: ({ id, itemTypes }) => ({
        url: `User/${id}/Payments`,
        method: "POST",
        body: { creditSpendType: itemTypes },
      }),
      transformResponse: (response: { data: any }) => response.data,
    }),
    getUserPDF: builder.mutation<any, { id: string | undefined, itemType: number }>({
      query: ({ id, itemType }) => ({
        url: `User/${id}/PDFPayment`,
        method: "POST",
        body: { creditSpendType: itemType },
      }),
      invalidatesTags: ["User"],
      transformResponse: (response: { data: any }) => response.data,
    }),
    getUserVideo: builder.mutation<any, { id: string | undefined, itemType: number, storyItemSubType: number }>({
      query: ({ id, itemType, storyItemSubType }) => ({
        url: `User/${id}/VideoPayment`,
        method: "POST",
        body: {
          creditSpendType: itemType,
          storyItemSubType: storyItemSubType,
        },
      }),
      invalidatesTags: ["User"],
      transformResponse: (response: { data: any }) => response.data,
    }),
    updateKYC: builder.mutation<ResponseUpdateKYC, UpdateKYCParams>({
      query: (data) => {
        const formData = new FormData();

        Object.keys(data).forEach((key) => {
          const value = data[key as keyof typeof data];
          if (value === null || value === "") {
            formData.append(key, "");
          } else if (value instanceof File) {
            formData.append(key, value);
          } else {
            formData.append(key, value.toString());
          }
        });

        return {
          url: 'User/UpdateKYC',
          method: 'POST',
          body: formData,
        }
      },
      invalidatesTags: ["User"],
      transformResponse: (response: { data: ResponseUpdateKYC }) => response.data,
    }),
    toggleDefaultStoryPrivacy: builder.mutation<void, void>({
      query: () => ({
        url: 'User/ToggleDefaultStoryPrivacy',
        method: 'PUT',
      }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const {
  useGetUserQuery,
  useLazyGetUserQuery,
  useRemoveCardMutation,
  useRegisterUserMutation,
  useResendEmailVerificationMutation,
  useVerifyEmailMutation,
  useSubmitContactUsFormMutation,
  useUpdateNotificationPreferencesMutation,
  // useGetBookOrdersQuery,
  useEditProfileDetailsMutation,
  useDeleteUserMutation,
  usePurchaseCreditsMutation,
  useVerifyPurchaseCreditsMutation,
  useGetTokenBalanceQuery,
  useGetTransactionsQuery,
  useGetUserProfileInfoQuery,
  useGetUserPurchaseQuery,
  useGetUserPDFMutation,
  useGetUserVideoMutation,
  useUpdateKYCMutation,
  useToggleDefaultStoryPrivacyMutation,
} = userApi;

import { Dialog, Tab, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import dynamic from 'next/dynamic';

import { useGetUser, useStripeSetup } from '@/utils/app-hooks';
import useTokenRateCard from '../../utils/wallet/use-token-rate-card';
import { userHasCard } from '@/utils/dataUtils';
const DynamicStripeForm = dynamic(
	() => import('../forms/stripe-form'),
	{
		loading: () => <p>Loading...</p>,
		ssr: false
	}
);
import LoadingIndicator from '../loaders/loading-indicator';
import PaymentCard from '../cards/payment-card';
import {usePurchaseCreditsMutation, CreditPurchaseType, useVerifyPurchaseCreditsMutation} from '@/store/apis/user-api';
import { useAppSelector } from '@/store/store';
import ChevronDownIcon from '../../../public/assets/icons/chevron-down.svg';

import { Disclosure } from '@headlessui/react';
import { env } from "@/utils/env";
import { PriceList } from '@/utils/wallet/constants';
import { useLogEvent } from '@/utils/analytics';
import { useRouter } from 'next/router';
import Link from 'next/link';


const MINIMUM_COST = 15;

export enum TabValue {
	Tab1 = 1,  // related to: CreditPurchaseType.Teaser
	Tab2, // related to: CreditPurchaseType.Leaflet
	Tab3, // related to: CreditPurchaseType.Anthology
	Tab4, // related to: CreditPurchaseType.Library
}

export type AddFundsDialogVariants = "add-balance" | "direct-purchase"

export default function TokenDialog({ variant, isOpen, closeDialog }:
																			{
																				variant: AddFundsDialogVariants,
																				isOpen: boolean;
																				closeDialog: () => void;
																			}) {
	const logEvent = useLogEvent();

	const tabs = useTokenRateCard();
	const tabsWithCosts = tabs.map((tab) => ({
		...tab,
		cost: {
			inUSDWithDiscount: tab.details.costInUSD,
			inUSDNoDiscount: tab.tokens / 20
		}
	}))

	const [stripeReady, stripeConfirmSetup, stripeConfirmPayment] = useStripeSetup();

	const [submitting, setSubmitting] = useState(false);
	const [showStripeForm, setShowStripeForm] = useState(false);
	const [user, userLoading] = useGetUser();
	const [purchaseCredits] = usePurchaseCreditsMutation();
	const [verifyPurchaseCredits] = useVerifyPurchaseCreditsMutation();
	const [isAuthorized, setIsAuthorized] = useState(false);

	const actionId = useAppSelector((state) => state.dialog.actionId);
	const tokenBalance = useAppSelector((state) => state.tokenBalance.totalTokens);

	const router = useRouter();

	/* Redux Global State ------------------------------------------------------- */
	const insufficientFunds = useAppSelector((state) => state.dialog.insufficientFunds);

	// tabs
	const [selectedTabIndex, setSelectedTabIndex] = useState(TabValue.Tab2);
	const selectedTab = tabsWithCosts[selectedTabIndex - 1]

	const showSelectFundsTabs = variant === "add-balance"

	// more
	const selectedItemToBuy = PriceList.find(item => item.id === actionId);

	// costs
	const costInTokens = selectedItemToBuy?.cost ?? 0;
	const costInUSD = costInTokens / 20

	if (variant === "direct-purchase" && costInUSD < MINIMUM_COST) {
		variant = "add-balance";
	}

	const totalCostNoDiscount = variant === "add-balance"? selectedTab.cost.inUSDNoDiscount : costInUSD;
	const totalCost = variant === "add-balance"? selectedTab.cost.inUSDWithDiscount: costInUSD;


	useEffect(() => {
		if (insufficientFunds && variant === "add-balance") {
			const required = costInTokens - tokenBalance;

			if (required > 0) {
				const suitableTab = tabs.find(tab => tab.tokens > required);
				if (suitableTab) {
					const index = tabs.indexOf(suitableTab);
					setSelectedTabIndex(index + 1); // +1 as your tabs start from 1
				}
			}
		}
	}, [insufficientFunds, variant]);

	function classNames(...classes: string[]) {
		return classes.filter(Boolean).join(' ');
	}

	const purchaseTokens = async () => {
		const url = env("location") + router.asPath
		try {
			setSubmitting(true);

			// Check if card details were updated
			if (showStripeForm || !userHasCard(user)) {
				const { error } = (await stripeConfirmSetup(url)) || {};
				if (error) {
					console.error('Stripe Error: ', error);
					toast.error(`Failed to save card: ${error.message}`);
					return;
				}
				else {
					toast.success('Card added, attempting purchase...');
				}
			}

			const result = await purchaseCredits({
				creditPurchaseType: variant === "add-balance"? selectedTabIndex as unknown as CreditPurchaseType: CreditPurchaseType.Supplement,
				creditSpendType: variant === "direct-purchase"? selectedItemToBuy?.creditSpendType: undefined
			});

			if ('data' in result) {
				if (result.data.nextAction && result.data.nextAction.type === 'use_stripe_sdk') {
					toast.loading('Waiting for card authentication...');
					logEvent('stripe_modal_requires_action');

					const {paymentIntent, error } =  await stripeConfirmPayment(result.data.clientSecret);

					toast.dismiss();

					if (error) {
						console.error('Stripe Error: ', error);
						logEvent('stripe_modal_purchase_failed');
						toast.error(`Purchase failed: ${error.message}`);
					}
					else if (paymentIntent) {
						if (paymentIntent.status === 'succeeded') {
							const verifyResponse = await verifyPurchaseCredits({
								creditPurchaseType: variant === "add-balance"? selectedTabIndex as unknown as CreditPurchaseType: CreditPurchaseType.Supplement,
								paymentIntentId: paymentIntent.id,
								amount: totalCostNoDiscount,
							});
							if ('data' in verifyResponse) {
								if (verifyResponse.data.succeeded) {
									logEvent('stripe_modal_purchase_success');
									toast.success('Purchase successful!');
									closeDialog();
									return;
								}
								else {
									logEvent('stripe_modal_purchase_failed');
									toast.error('Internal Server Error: please contact support.');
									return;
								}
							}
							else {
								logEvent('stripe_modal_purchase_failed');
								toast.error('Purchase failed.');
							}
						}
						else {
							logEvent('stripe_modal_purchase_failed');
							toast.error('Purchase failed.');
						}
					}
				}
				else if (result.data.succeeded && !result.data.requiresAction) {
					logEvent('stripe_modal_purchase_success');
					toast.success('Purchase successful!');
					closeDialog();
				}
				else {
					logEvent('stripe_modal_purchase_failed');
					toast.error('Purchase failed.');
				}
			} else {
				logEvent('stripe_modal_purchase_failed');
				toast.error('Purchase failed.');
			}
		} catch (error) {
			console.error('Stripe Error: ', error);
			logEvent('stripe_modal_purchase_failed');
			toast.error(`Purchase failed: ${error}`);
		} finally {
			localStorage.setItem('lastTokenModalTimestamp', Date.now().toString());
			setSubmitting(false);
		}
	};

	logEvent('stripe_modal_loaded');

	return (
		<Transition
			show={isOpen}
			as={Fragment}>
			<Dialog
				open={isOpen}
				onClose={() => closeDialog()}
				className='relative z-50 mx-auto'>

				{/* The backdrop, rendered as a fixed sibling to the panel container */}
				<div
					className='fixed inset-0 bg-black/30'
					aria-hidden='true'
				/>
				<Dialog.Panel className='fixed inset-0 md:flex md:justify-center md:items-center overflow-auto'>
					<div className='flex flex-col grow overflow-y-auto max-h-screen bg-yellow-50 w-full max-w-3xl transform md:rounded-2xl md:p-16 p-5 text-left shadow-xl transition-all'>
						<div className="text-center font-bold text-2xl mb-5">
							Add Funds to your Account
						</div>

						{/* Insufficient Funds Notice */}
						{insufficientFunds && <DialogHeader name='insufficientFunds' actionId={actionId} />}

						{/* Normal Title and Description */}
						{!insufficientFunds && <DialogHeader name='purchaseTokens' />}

						<p className='text-lg text-neutral-600 font-bold my-5 md:m-0 text-center'>
							This is a one-time purchase only.
						</p>

						{/* Token Picker */}
						{
							showSelectFundsTabs && (
								<div className='w-full max-w-3xl md:px-2 px-0 py-4 sm:px-0 mx-auto'>
									<Tab.Group selectedIndex={selectedTabIndex - 1}>
										<Tab.List className='flex space-x-1 md:rounded-xl rounded-sm bg-background/40 p-2'>
											{tabsWithCosts.map((tab, index) => (
												<Tab
													key={`${tab.tokens}-${index}`}
													onClick={() => setSelectedTabIndex(index + 1)}
													className={({ selected }) =>
														classNames(
															'w-full rounded-lg py-2.5 sm:text-sm text-xs font-medium leading-5 text-blue-700',
															'ring-gold ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-4',
															'flex flex-col justify-center items-center',
															selected
																? 'bg-yellow-50'
																: 'text-blue-100 hover:bg-white/[0.12] hover:text-primary-400'
														)
													}>
													<div className='flex items-center space-x-2'>
												<span className='text-base font-semibold'>
													${tab.cost.inUSDWithDiscount}
												</span>
														{tab.cost.inUSDNoDiscount > tab.cost.inUSDWithDiscount &&
															<span className='text-base line-through text-gray-500'>
														${tab.cost.inUSDNoDiscount}
													</span>
														}
													</div>
													{tab.cost.inUSDNoDiscount > tab.cost.inUSDWithDiscount &&
														<div className='text-xs text-green-500 mt-1'>
															Save ${tab.cost.inUSDNoDiscount - tab.cost.inUSDWithDiscount}!
														</div>
													}
												</Tab>
											))}
										</Tab.List>
										<Tab.Panels className='mt-2 shadow-lg border-2 border-yellow-100 rounded-lg'>
											{tabsWithCosts.map((tab, index) => (
												<Tab.Panel
													key={`${tab.tokens}-${index}`}
													className={classNames(
														'rounded-xl bg-yellow-50 p-3',
														'ring-gold ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2')}>
													{actionId ? (
															<>
																<div className={`flex items-center gap-1 text-sm p-3 w-full min-w-full`}>
														<span>
															With <strong>${tab.cost.inUSDNoDiscount}</strong>, you get:
														</span>
																</div>

																<div>
																	<ul className='grid md:grid-cols-2 grid-cols-1 gap-2 mt-2'>
																		{tab.availableItems.filter((item) => item.id === actionId).map((item, index) => (
																			<li
																				key={`${item.name}-${index}`}
																				className='flex-grow flex-shrink rounded-md border-[0.5px] border-yellow-100 bg-gold/20 p-3 hover:bg-gray-100'>
																				<h3 className='sm:text-sm text-xs font-medium leading-5'>
																		<span>
																			{item.icon}
																			<strong>{item.qty}x</strong> {item.name}
																		</span>
																				</h3>
																			</li>
																		))}
																	</ul>
																</div>
															</>)
														: (
															<Disclosure defaultOpen>
																{({ open }) => (
																	<>
																		<Disclosure.Button className="flex items-center gap-1 text-sm p-3 w-full min-w-full">
																	<span>
																		With <strong>${tab.cost.inUSDNoDiscount}</strong>, you get:
																	</span>

																			<ChevronDownIcon
																				className={`ml-auto transition-transform duration-200 ${open ? 'transform rotate-180' : ''}`}
																				aria-hidden="true"/>

																		</Disclosure.Button>
																		<Disclosure.Panel>
																			<ul className='grid md:grid-cols-2 grid-cols-1 gap-2 mt-2'>
																				{tab.availableItems.filter((item) => item.id !== "edit").map((item, index) => ( //if edit cost increases from zero, add cost to availableItems to filter out cost <= 0
																					<li
																						key={`${item.name}-${index}`}
																						className='flex-grow flex-shrink rounded-md border-[0.5px] border-yellow-100 bg-gold/20 p-3 hover:bg-gray-100'>
																						<h3 className='sm:text-sm text-xs font-medium leading-5'>
																					<span>
																						{item.icon}
																						<strong>{item.qty}</strong> {item.name === "Published Book on Amazon" ?
																						<strong>{item.name}</strong> : item.name}
																					</span>
																						</h3>
																					</li>
																				))}
																			</ul>
																		</Disclosure.Panel>
																	</>
																)}
															</Disclosure>
														)
													}
												</Tab.Panel>
											))}
										</Tab.Panels>
									</Tab.Group>
								</div>
							)
						}

						{showStripeForm || !userHasCard(user) ? (
							<div>
								<DynamicStripeForm onStripeReady={stripeReady} />
							</div>
						) : userLoading ? (
							<div className='flex min-h-[280px]'>
								<LoadingIndicator />
							</div>
						) : (
							<div className='flex justify-center items-center flex-col py-5 md:mb-15 mb-5'>
								<div className="hidden">
									<DynamicStripeForm onStripeReady={stripeReady} />
								</div>
								<PaymentCard />
								<button
									onClick={() => setShowStripeForm(true)}
									className='font-semibold max-w-max text-primary-500 hover:text-primary-600 hover:underline'>
									Change card details
								</button>
							</div>
						)}
						{/* Checkout Price Display */}
						{!userLoading && (
							<div className='flex justify-center items-center flex-col my-3'>
								<div className='bg-gray-100 p-4 rounded-md w-full'>
									<h3 className='text-lg font-semibold mb-2'>Order Summary</h3>
									<div className='flex justify-between'> {/* Bold for final price */}
										<span>Funds To Add:</span>
										<span>${(totalCostNoDiscount).toFixed(2)}</span>
									</div>
									{totalCostNoDiscount > totalCost && (
										<div className='text-success-500 flex justify-between mt-1'>
											<span>Discount:</span>
											<span>${(totalCostNoDiscount - totalCost).toFixed(2)}</span>
										</div>
									)}
									{/* Consider adding a section for taxes if necessary */}
									{/* <div className='flex justify-between'>
											<span>Tax (XX%):</span>
											<span>${calculateTax(totalCostNoDiscount)}</span>
									 	 </div> */}
									<div className='flex justify-between text-lg font-bold mt-2'>
										<span>Checkout Price:</span>
										<span>${totalCost.toFixed(2)}</span>
									</div>
								</div>
							</div>
						)}


						<div className="my-3 flex items-center"> {/* Adjust margin as needed */}
							<div>
								<input
									id="authorization"
									type="checkbox"
									className="form-checkbox h-5 w-5 text-blue-600" // You can adjust the color and size.
									checked={isAuthorized}
									onChange={() => setIsAuthorized(!isAuthorized)}
								/>
								<label htmlFor="authorization" className="ml-2 text-sm text-gray-700 cursor-pointer">
									I authorize this purchase.
								</label>
							</div>

						</div>
						<div className="flex">
								<p className="text-error-500">Note: Your payment will be processed by <Link href={"https://story.com"} target={"_blank"} className={"underline text-[#3A54CB]"}>Story.com</Link> - <Link href="/blogs/story-transition" target="_blank" className="underline">Learn More</Link></p>
						</div>


						<div className='flex justify-center items-center flex-col-reverse md:flex-row'>
							<div className='flex flex-row-reverse justify-center items-center gap-5 flex-wrap text-sm md:text-md'>
								<button
									disabled={!isAuthorized || submitting}
									onClick={() => purchaseTokens()}
									className='btn-primary'>
									{submitting ? 'Adding funds' : 'Add Funds'}
								</button>
								<Link
									href={`#`}
									onClick={() => {
										localStorage.setItem('lastTokenModalTimestamp', Date.now().toString());
										closeDialog();
									}}
									className='btn-secondary'>
									Cancel
								</Link>
							</div>
						</div>
						<div className='text-center text-neutral-500'>
							<button
								onClick={() => {
									localStorage.setItem('lastTokenModalTimestamp', Date.now().toString());
									closeDialog();
								}}
								className='text-sm mt-8 text-gray-600 hover:text-gray-800 underline'>
								Remind me later
							</button>
						</div>
					</div>
				</Dialog.Panel>
			</Dialog>
		</Transition>
	);
}

// TODO: Move to global types

type DialogHeaderName = 'purchaseTokens' | 'insufficientFunds';

interface DialogHeaderInterface {
	name: DialogHeaderName;
	title: string;
	description: string;
}

const useGenerateHeader = (name: DialogHeaderName, cost: number, actionId?: string): DialogHeaderInterface | undefined => {
	const tokenBalance = useAppSelector((state) => state.tokenBalance.totalTokens);

	const actionType = (actionId: string | undefined) => {
		switch (actionId) {
			case 'drawingMode':
				return 'Using Drawing Mode';
			case 'exportVideoLandscape':
			case 'exportVideoPortrait':
			case 'exportVideoLandscapeSubtitle':
				return 'Exporting a Video';
			case 'WatermarkedEBookPDF':
			case 'OriginalEBookPDF':
			case 'WatermarkedStoryBookPDF':
			case 'OriginalStoryBookPDF':
				return 'Downloading a PDF';
			case 'publishPDF':
				return 'Purchasing an Amazon PDF Package';
			case 'publish':
				return 'Publishing to Amazon';
			case 'imageGen':
				return 'Generating a new image'
			case 'audioRegen':
				return 'Regenerating an audio'
			case 'audioGen':
				return 'Generating a new audio'
			case 'regenerateTextSegment':
				return 'Regenerating a text segment'
			case 'edit':
				return 'Editing your story'
			case 'storyGen':
				return 'Generating a story'
			default:
				return 'This action'
		}
	}

	const headerTitle = (actionId: string | undefined) => {
		switch (actionId) {
			case 'drawingMode':
				return 'Add funds to Use Drawing Mode';
			case 'exportVideoLandscape':
			case 'exportVideoPortrait':
			case 'exportVideoLandscapeSubtitle':
				return 'Add funds to Export a Video';
			case 'addNewPage':
				return 'Add funds to Add a New Page';
			case 'regenerateTextSegment':
				return 'Add funds to Regenerate a Text Segment';
			case 'WatermarkedEBookPDF':
			case 'OriginalEBookPDF':
			case 'WatermarkedStoryBookPDF':
			case 'OriginalStoryBookPDF':
				return 'Add funds to Download a PDF';
			case 'publish':
				return 'Add funds to Publish your Book';
			case 'imageGen':
				return 'Add funds to Generate a New Image'
			case 'audioRegen':
				return 'Add funds to Regenerate an Audio'
			case 'audioGen':
				return 'Add funds to Generate a New Audio'
			case 'edit':
				return 'Add funds to Edit your Story'
			case 'storyGen':
				return 'Add funds to Generate your Story'
			default:
				return ''
		}
	}

	switch (name) {
		case 'purchaseTokens':
			return {
				name: 'purchaseTokens',
				title: 'Add funds',
				description:
					'Cheers to your next chapter with StoryBird! Top up your funds and you\'re all set to sprinkle your tales with AI magic. Click "Add Funds" to begin your journey of enchanting storytelling and story sharing experience.',
			}
		case 'insufficientFunds':
			return {
				name: 'insufficientFunds',
				title: headerTitle(actionId),
				description: actionType(actionId) + " requires $" + cost / 20 + ".",
			}
		default:
			return undefined;
	}


}

function DialogHeader({ name, actionId }: { name: DialogHeaderName, actionId?: string }) {

	const paidAction = PriceList.find(item => item.id === actionId);
	const { cost } = paidAction ? paidAction : { cost: 0 };

	const header = useGenerateHeader(name, cost, actionId);

	if (!header) throw new Error(`Invalid DialogHeader name: ${name}`);


	return (
		<div className={`md:mb-10 mb-2 md:text-md text-md bg-[#DFF2BF] rounded-md p-4 flex items-center`}>
			<Dialog.Title className='mb-2 md:text-md lg:text-lg text-sm font-medium leading-6 text-gray-900'>
			</Dialog.Title>
			<Dialog.Description className={'ml-8'}>
				{header.description}
			</Dialog.Description>
		</div>
	);
}
export default function Maintenance() {
    return (
        <div className="flex-1 grid place-items-center p-4">
            <div className="flex flex-col w-full max-w-md gap-4">
                <h1 className="text-2xl sm:text-3xl md:text-4xl font-aleo font-semibold">
                    We&apos;ll be back soon!
                </h1>
                <p>
                    StoryBird.ai is currently undergoing scheduled maintenance to enhance your
                    story-telling experience. We'll be back in no time to unleash the magic of
                    creativity once again. Thank you for your patience.
                </p>
                <p>The StoryBird.ai team</p>
            </div>
        </div>
    );
}

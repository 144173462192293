export default function LoadingIndicator({
    pixelHeight,
    strokeWidth,
}: {
    pixelHeight?: number;
    strokeWidth?: number;
}) {
    return (
        <div className="grid place-items-center flex-1">
            <div
                style={{
                    borderWidth: `${strokeWidth || 5}px`,
                    height: `${pixelHeight || 48}px`,
                }}
                className="aspect-[1/1] border-brand-fill rounded-full border-b-transparent animate-spin"
            />
        </div>
    );
}

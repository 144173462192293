import React, {useEffect, useState} from "react";

import { useCanScroll } from "../../utils/app-hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useRouter } from 'next/router';
import { useAppSelector } from "../../store/store";
import { env } from "../../utils/env";
import Link from "next/link";
import Modal, {CallerFn} from "@/components/modals/modal";
import VerifyEmailModal from "@/components/modals/verify-email-modal";
import StoryTransitionModal from "@/components/modals/story-transition-modal";

export default function SideNav({ isOpen, close }: { isOpen: boolean; close(): void }) {
    const { hasAuth } = useAppSelector(({ auth }) => auth);
    const [blockScroll, allowScroll] = useCanScroll();
    const router = useRouter();

    const { loginWithRedirect } = useAuth0();
    const tokenBalance = useAppSelector((state) => state.tokenBalance.totalTokens);
    const pathname = router.pathname;
    const [verifyEmailModal, setVerifyEmailModal] = useState<CallerFn>();
    const [transitionModal, setTransitionModal] = useState<CallerFn>();


    useEffect(() => {
        close();
    }, [pathname]);

    useEffect(() => {
        isOpen ? blockScroll() : allowScroll();
    }, [isOpen]);

    useEffect(() => {
        const onResize = () => window.innerWidth > 768 && close();

        if (isOpen) window.addEventListener("resize", onResize);

        return () => window.removeEventListener("resize", onResize);
    }, [isOpen]);

    const signInHandler = async () => await loginHandler('login');
    const signUpHandler = async () => await sighUpHandler("signup");

    const loginHandler = (screen_hint: "signup" | "login") =>
      loginWithRedirect({
          screen_hint,
          audience: env("oauthAudience"),
          appState: { returnTo: pathname || "/" },
      });

    const sighUpHandler = async (screen_hint: "signup" | "login") => {
        if (!transitionModal) return;
        try {
            await transitionModal();
            router.push("https://story.com/auth/login");
        } catch {  }
    }


    return (
        <>
            {isOpen ? <div className="fixed inset-0 bg-black/60 z-20" onClick={close} /> : null}
            <aside className={`side-nav ${isOpen ? "open" : ""}`}>
                <button onClick={close} className="absolute top-2 right-2 p-2 rounded-full hover:bg-primary-300 hover:opacity-75" aria-label="Close menu">
                    <XMarkIcon className="w-6 h-6" aria-hidden />
                </button>
                {hasAuth ? (
                    <div className="flex flex-col gap-1 items-center mx-auto mt-8">
                        <span className="block text-sm font-medium tracking-wide text-neutral-700">
                            Balance
                        </span>
                        <span className="block text-4xl font-semibold">
                            ${tokenBalance ? tokenBalance/20 : 0}
                        </span>
                        <Link href="/dashboard/wallet" className="block underline hover:opacity-75 hover:no-underline text-sm mt-2 mx-auto" onClick={close}>Manage Wallet</Link>
                    </div>
                ) : null}
                <nav className={classNames('flex flex-1 flex-col py-5 w-full', {
                    "mt-10": !hasAuth
                })} aria-label="primary">
                    {hasAuth ? (
                        <Link
                            href="/dashboard"
                            aria-current={pathname.includes("/dashboard") ? "page" : undefined}
                            className="side-nav-link">
                            <span>Dashboard</span>
                        </Link>
                    ) : (
                        <div className="flex justify-center items-center gap-4 pb-5">
                            <button onClick={signInHandler} className="btn-secondary">
                                Sign in
                            </button>
                            <button onClick={signUpHandler} className="btn-primary">
                                Sign up
                            </button>
                        </div>
                    )}
                    <Link
                        href="https://story.com"
                        className="side-nav-link"
                    >
                        <span>Story.com</span>
                    </Link>
                    {/*<Link*/}
                    {/*    href="/library"*/}
                    {/*    className="side-nav-link"*/}
                    {/*    aria-current={pathname.includes("/library") ? "page" : undefined}>*/}
                    {/*    <span>Library</span>*/}
                    {/*</Link>*/}
                    {/*<Link*/}
                    {/*    href="/create-story"*/}
                    {/*    className="side-nav-link"*/}
                    {/*    aria-current={pathname.includes("/create-story") ? "page" : undefined}>*/}
                    {/*    <span>Create</span>*/}
                    {/*</Link>*/}
                    {/*<Link*/}
                    {/*    href="/about"*/}
                    {/*    className="side-nav-link"*/}
                    {/*    aria-current={pathname.includes("/about") ? "page" : undefined}>*/}
                    {/*    <span>About</span>*/}
                    {/*</Link>*/}
                    {/*<Link*/}
                    {/*    href="/chatgpt-stories-plugin"*/}
                    {/*    aria-current={*/}
                    {/*        pathname.includes("/chatgpt-stories-plugin") ? "page" : undefined*/}
                    {/*    }*/}
                    {/*    className="side-nav-link">*/}
                    {/*    <span>Plugin</span>*/}
                    {/*</Link>*/}
                    <Link
                        href="/blogs/story-transition"
                        aria-current={
                            pathname.includes("/chatgpt-stories-plugin") ? "page" : undefined
                        }
                        className="side-nav-link">
                        <span>FAQs</span>
                    </Link>
                </nav>
            </aside>
            <Modal registerHandler={setTransitionModal} content={StoryTransitionModal} />
        </>
    );
}

import { Env } from "./utils/env";

export const envs: {
    unstable?: Env;
    staging?: Env;
    prod: Env;
    local: Env;
    unused?: Env;
    stagingLocal: Env;
    prodOldStorybird: Env;
} = {
    stagingLocal: {
        production: false,
        location: "http://localhost:3000",
        api_url: "https://api.staging.storybird.ai/api",
        oauthDomain: "auth.storybird.ai",
        oauthAudience: "https://api.staging.storybird.ai/",
        oauthClientId: "RAr9tJS2cLL1ayawFM5kbDUlzQg7hdo8",
        stripeKey:
            "pk_test_51J0c3gIIeCMQTvuM82K67qaqQwZF7xo9uzrTaeguRCOyG6qkj56pBAvYVPwbb43xfPbC5gNxRtUGQeTSOzIaaApX008x577fqL",
        shareUrl: "https://staging.storybird.ai",
        imageKit: "https://ik.imagekit.io/storybird/staging/",
        publicUrl: "https://storybird-public-staging.s3.us-west-2.amazonaws.com/",
        imageScribble: "https://imageapi.storybird.ai",
    },
    staging: {
        production: false,
        location: "https://staging.storybird.ai",
        api_url: "https://api.staging.storybird.ai/api",
        oauthDomain: "auth.storybird.ai",
        oauthAudience: "https://api.staging.storybird.ai/",
        oauthClientId: "RAr9tJS2cLL1ayawFM5kbDUlzQg7hdo8",
        stripeKey:
            "pk_test_51J0c3gIIeCMQTvuM82K67qaqQwZF7xo9uzrTaeguRCOyG6qkj56pBAvYVPwbb43xfPbC5gNxRtUGQeTSOzIaaApX008x577fqL",
        shareUrl: "https://staging.storybird.ai",
        imageKit: "https://ik.imagekit.io/storybird/staging/",
        publicUrl: "https://storybird-public-staging.s3.us-west-2.amazonaws.com/",
        imageScribble: "https://imageapi.storybird.ai",
    },
    local: {
        production: false,
        location: "http://localhost:3000",
        api_url: "http://localhost:5000/api",
        oauthDomain: "auth.storybird.ai",
        oauthAudience: "http://localhost:5000",
        oauthClientId: "ge2M0U9KOxwr015TTSIp8tmCAdwE4gff",
        stripeKey:
            "pk_test_51J0c3gIIeCMQTvuM82K67qaqQwZF7xo9uzrTaeguRCOyG6qkj56pBAvYVPwbb43xfPbC5gNxRtUGQeTSOzIaaApX008x577fqL",
        shareUrl: "https://staging.storybird.ai",
        imageKit: "https://ik.imagekit.io/storybird/staging/",
        publicUrl: "https://storybird-public-staging.s3.us-west-2.amazonaws.com/",
        imageScribble: "https://imageapi.storybird.ai",
    },
    prod: {
        production: true,
        location: "https://storybird.ai",
        api_url: "https://api.storybird.ai/api",
        oauthDomain: "auth.storybird.ai",
        oauthAudience: "https://api.storybird.ai/",
        oauthClientId: "EuKxMzA2g75eGZBfaZ7DopwEMvbQS2nM",
        stripeKey:
            "pk_live_51J0c3gIIeCMQTvuMaFkP4S2OOgPvFlVSqqVFPc9bQAw53KnXuwnAHJEcuH6t1erTo7MT53XPd0aBjXP04OZILBOi00rxhaEDJk",
        shareUrl: "https://storybird.ai",
        imageKit: "https://ik.imagekit.io/storybird/",
        publicUrl: "https://storybird-public.s3.us-west-2.amazonaws.com/",
        imageScribble: "https://imageapi.storybird.ai",
    },
    prodOldStorybird: {
        production: true,
        location: "https://old.storybird.ai",
        api_url: "https://api.storybird.ai/api",
        oauthDomain: "auth.storybird.ai",
        oauthAudience: "https://api.storybird.ai/",
        oauthClientId: "EuKxMzA2g75eGZBfaZ7DopwEMvbQS2nM",
        stripeKey:
          "pk_live_51J0c3gIIeCMQTvuMaFkP4S2OOgPvFlVSqqVFPc9bQAw53KnXuwnAHJEcuH6t1erTo7MT53XPd0aBjXP04OZILBOi00rxhaEDJk",
        shareUrl: "https://old.storybird.ai",
        imageKit: "https://ik.imagekit.io/storybird/",
        publicUrl: "https://storybird-public.s3.us-west-2.amazonaws.com/",
        imageScribble: "https://imageapi.storybird.ai",
    },
};

// src/store/dialogSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddFundsDialogVariants } from "@/components/dialogs/token-dialog";

interface DialogState {
  isOpen: boolean;
  insufficientFunds: boolean;
  actionId: string;
  variant: AddFundsDialogVariants,
  defaultPopup?: boolean;
}

const initialState: DialogState = {
  isOpen: false,
  insufficientFunds: false,
  actionId: '',
  variant: "add-balance",
  defaultPopup: false,
};

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    openDialog: (state, action: PayloadAction<{insufficientFunds?: boolean, actionId?: string, variant?: AddFundsDialogVariants} | undefined>) => {
      state.isOpen = true;
      state.insufficientFunds = action.payload?.insufficientFunds ?? false;
      state.actionId = action.payload?.actionId ?? '';
      state.variant = action.payload?.variant ?? "add-balance"
    },
    closeDialog: (state) => {
      state.isOpen = false;
      state.insufficientFunds = false;
      state.actionId = '';
    },
  },
});

export const { openDialog, closeDialog } = dialogSlice.actions;

export default dialogSlice.reducer;
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useRef, useState } from "react";
import { useGetUserQuery, useLazyGetUserQuery } from "@/store/apis/user-api";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { env } from "@/utils/env";
import { api } from "@/store/apis/api";
import {PaymentIntent, SetupIntent, Stripe, StripeElements, StripeError} from "@stripe/stripe-js";
import { useSyncCardsMutation } from "@/store/apis/payment-api";
import { User } from "@/models";
import { clearAuthState, setToken } from "@/store/auth";
import { parseJwt } from "@/utils/dataUtils";
import { dayjs } from "../utils/dayjsWrapper";
import { CallerFn } from "@/components/modals/modal";
import { useRouter } from "next/router";

export function useAuthRedirect() {
    const { loginWithRedirect } = useAuth0();

    const redirectToLogin = (returnPath = window.location.pathname, additionalArgs = {}) => {
        loginWithRedirect({
            ...additionalArgs,
            appState: {
                returnTo: returnPath,
                ...additionalArgs,
            },
        }).then();
    };

    const redirectToSignup = (returnPath = window.location.pathname, additionalArgs = {}) => {
        loginWithRedirect({
            ...additionalArgs,
            screen_hint: "signup",
            appState: {
                returnTo: returnPath,
                ...additionalArgs,
            },
        }).then();
    };

    return {
        redirectToLogin,
        redirectToSignup,
    };
}

export const useLogout = () => {
    const { logout, isAuthenticated } = useAuth0();
    const dispatch = useAppDispatch();

    return (override = false) => {
        dispatch(
            api.util.invalidateTags([
                "User",
                "UserLib",
                "UserStory",
                "BookOrders",
                "StoryInteractions",
                "Claim",
            ])
        );
        dispatch(clearAuthState());
        if (!override && !isAuthenticated) return;
        localStorage.removeItem("jwt");
        logout({ returnTo: env("location") });
    };
};

export const useVerifyEmail = () => {
    const [user] = useGetUser();
    const [getUser] = useLazyGetUserQuery();
    const [verifyEmailModal, setVerifyEmailModal] = useState<CallerFn>();

    const showVerifyModal = async () => {
        if (!user || user?.emailVerified || !verifyEmailModal) return;
        try {
            await verifyEmailModal();
        } catch {
            return await new Promise<void>(async (res, rej) => {
                if ((await getUser().unwrap()).emailVerified) return res();
                rej();
            });
        }
    };

    return { verifyEmailModal, setVerifyEmailModal, showVerifyModal };
};

export const useStripeSetup = () => {
    const [syncCards] = useSyncCardsMutation();
    const [stripe, setStripe] = useState<Stripe>();
    const [elements, setElements] = useState<StripeElements>();

    const stripeReady = (stripe: Stripe, elements: StripeElements) => {
        setStripe(stripe);
        setElements(elements);
    };

    const stripeConfirmPayment = async (clientSecret: string) => {
        console.log("stripeConfirmPayment", clientSecret)
        if (!stripe) return;

        // Use handleCardAction for 3D Secure authentication
        const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret);
        console.log("stripeConfirmPayment", paymentIntent, error);
        return { paymentIntent, error };
    };

    const stripeConfirmSetup = async (return_url: string) => {
        if (!stripe || !elements) return;

        const { error, setupIntent } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url,
            },
            redirect: "if_required",
        });
        await syncCards().unwrap();
        return { error, setupIntent };
    };

    return [stripeReady, stripeConfirmSetup, stripeConfirmPayment] as [
        (stripe: Stripe, elements: StripeElements) => void,
        (return_url: string) => Promise<{
            error: StripeError | undefined;
            setupIntent: SetupIntent | undefined;
        }>,
        (clientSecret: string) => Promise<{
            paymentIntent: PaymentIntent | undefined;
            error: StripeError | undefined;
        }>
    ];
};

export const useGetUser = () => {
    const { hasAuth } = useAppSelector(({ auth }) => auth);
    const { data, isLoading, isFetching } = useGetUserQuery(undefined, { skip: !hasAuth });
    return [data, isLoading || isFetching] as [User, boolean];
};

export const useGetToken = () => {
    const dispatch = useAppDispatch();
    const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

    return async () => {
        let token = localStorage.getItem("jwt");
        if (token) {
            const { exp } = parseJwt(token);
            if (dayjs(exp * 1000).diff(dayjs(), "minutes") < 30) {
                token = "";
            }
        }
        
        if (!token && !isLoading && isAuthenticated) {
            try {
                token = await getAccessTokenSilently({
                    audience: env("oauthAudience"),
                });
            } catch (e: any) {
                console.warn('Error during token fetching:', e);
            }
        }
        if (token) {
            localStorage.setItem("jwt", token);
            dispatch(setToken(token));
        } else {
            console.log('No token obtained.');
        }

        return token;
    };
};


export enum ScrollDirection {
    Up,
    Down,
}

export const useScrollDirection = () => {
    const threshold = 10;
    const [scrollDir, setScrollDir] = useState(ScrollDirection.Up);

    useEffect(() => {
        let previousScrollYPosition = window.scrollY;

        const scrolledMoreThanThreshold = (currentScrollYPosition: number) =>
            Math.abs(currentScrollYPosition - previousScrollYPosition) > threshold;

        const isScrollingUp = (currentScrollYPosition: number) =>
            currentScrollYPosition > previousScrollYPosition &&
            !(previousScrollYPosition > 0 && currentScrollYPosition === 0) &&
            !(currentScrollYPosition > 0 && previousScrollYPosition === 0);

        const updateScrollDirection = () => {
            const currentScrollYPosition = window.scrollY;

            if (scrolledMoreThanThreshold(currentScrollYPosition)) {
                const newScrollDirection = isScrollingUp(currentScrollYPosition)
                    ? ScrollDirection.Down
                    : ScrollDirection.Up;
                setScrollDir(newScrollDirection);
                previousScrollYPosition = currentScrollYPosition > 0 ? currentScrollYPosition : 0;
            }
        };

        const onScroll = () => window.requestAnimationFrame(updateScrollDirection);

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    return scrollDir;
};


export const useCanScroll = () => {
    const scrollBlocked = useRef(false);

    const blockScroll = () => {
        if (scrollBlocked.current) return;

        const html = document.documentElement;
        const body = document.body;

        if (!body || !body.style) return;

        const scrollBarWidth = window.innerWidth - html.clientWidth;
        const bodyPaddingRight =
            parseInt(window.getComputedStyle(body).getPropertyValue("padding-right")) || 0;

        html.style.position = "relative";
        html.style.overflow = "hidden";
        body.style.position = "relative";
        body.style.overflow = "hidden";
        body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

        scrollBlocked.current = true;
    };

    const allowScroll = () => {
        if (!scrollBlocked.current) return;

        const html = document.documentElement;
        const body = document.body;

        if (!body || !body.style) return;

        html.style.position = "";
        html.style.overflow = "";
        body.style.position = "";
        body.style.overflow = "";
        body.style.paddingRight = "";

        scrollBlocked.current = false;
    };

    return [blockScroll, allowScroll];
};


export const useQueryParams = () => {
    const router = useRouter();
    const search = router.asPath.split('?')[1] || '';

    const searchParams = new URLSearchParams(search);
    const params: { [key: string]: string | number | boolean } = {};

    searchParams.forEach((value, key) => {
        const v = value.toLowerCase();
        const valAsNum = Number(v);
        params[key] = isNaN(valAsNum)
            ? ["true", "false"].includes(v)
                ? v === "true"
                : value
            : valAsNum;
    });

    return params;
};


// useful to check window size in real time
// const isDesktop = useMediaQuery("(min-width: 1024px)");

export const useMediaQuery = (query: string) => {
    const [matches, setMatches] = useState(false);
  
    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      window.addEventListener("resize", listener);
      
      return () => window.removeEventListener("resize", listener);
    }, [matches, query]);
  
    return matches;
  }
import { envs } from "@/env";

export interface Env {
    production: boolean;
    api_url: string;
    oauthDomain: string;
    oauthClientId: string;
    oauthAudience: string;
    stripeKey: string;
    location: string;
    shareUrl: string;
    imageKit: string;
    publicUrl: string;
    imageScribble: string,
}
let currentEnv: any;
const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
try {
    if (isDev) {
        currentEnv = require("../env-name.ts").default;
    }
} catch (_) { }

export const envName: keyof typeof envs = isDev ? currentEnv || "staging" : "prod";

export function env<K extends keyof Env>(key: K): Env[K] {
    return (envs as any)[envName][key];
}

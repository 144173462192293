import {
	BookOpenIcon,
	CurrencyDollarIcon,
	MicrophoneIcon,
	PencilIcon,
	PhotoIcon,
	SpeakerWaveIcon,
} from '@heroicons/react/24/outline';
import {ReactElement} from "react";
import { CreditSpendType } from "@/models";

export const Denominations = [
	// If you enable this, change onClick={() => setSelectedTab(index + 2)} to +1 in token-dialog.tsx
	{
		name: 'Teaser',
		tokens: 300,
		costInUSD: 15,
	},
	{
		name: 'Leaflet',
		tokens: 630,
		costInUSD: 30,
	},
	{
		name: 'Anthology',
		tokens: 1100,
		costInUSD: 50,
	},
	{
		name: 'Library',
		tokens: 3600,
		costInUSD: 150,
	},
] as const;

type PriceListItem = {
	readonly id: string;
	readonly icon: ReactElement;
	readonly name: string;
	readonly perSegment: boolean;
	readonly cost: number;
	readonly creditSpendType: CreditSpendType;
	readonly originalCost?: number;
}

export const PriceList: PriceListItem[] = [
	{
		id: 'publish',
		icon: <CurrencyDollarIcon />,
		name: 'Published Book on Amazon',
		perSegment: false,
		cost: 2000,
		creditSpendType: CreditSpendType.AmazonPublishing
	},
	{
		id: 'storyGen',
		icon: <BookOpenIcon />,
		name: 'AI Story Generation',
		perSegment: false,
		cost: 20,
		creditSpendType: CreditSpendType.StoryGeneration

	},
	{
		id: 'edit',
		icon: <PencilIcon />,
		name: 'Paragraph Edit',
		perSegment: true,
		cost: 0,
		creditSpendType: CreditSpendType.StorySegmentEdit
	},
	{
		id: 'audioGen',
		icon: <MicrophoneIcon />,
		name: 'Story Audio Playback',
		perSegment: false,
		cost: 5,
		creditSpendType: CreditSpendType.AudioGeneration
	},
	{
		id: 'audioRegen',
		icon: <SpeakerWaveIcon />,
		name: 'Audio Segment Playback',
		perSegment: true,
		cost: 2,
		creditSpendType: CreditSpendType.AudioRegeneration
	},
	{
		id: 'imageGen',
		icon: <PhotoIcon />,
		name: 'AI Image Regeneration',
		perSegment: true,
		cost: 2,
		creditSpendType: CreditSpendType.ImageRegeneration
	},
	{
		id: 'publishPDF',
		icon: <CurrencyDollarIcon />,
		name: 'Publishable Story PDF for Amazon',
		perSegment: false,
		cost: 1000,
		creditSpendType: CreditSpendType.AmazonPublishingPDFOnly
	},
	{
		id: 'WatermarkedEBookPDF',
		icon: <CurrencyDollarIcon />,
		name: 'Download Story PDF',
		perSegment: false,
		cost: 400,
		creditSpendType: CreditSpendType.WatermarkedEBookPDF
	},
	{
		id: 'OriginalEBookPDF',
		icon: <CurrencyDollarIcon />,
		name: 'Download Story PDF',
		perSegment: false,
		cost: 500,
		creditSpendType: CreditSpendType.OriginalEBookPDF
	},
	{
		id: 'regenerateTextSegment',
		icon: <CurrencyDollarIcon />,
		name: 'Regenerate Text Segment Automatically',
		perSegment: false,
		cost: 5,
		creditSpendType: CreditSpendType.RegenerateSegmentWithAI
	},
	{
		id: 'addNewPage',
		icon: <CurrencyDollarIcon />,
		name: 'Add New Page to Story',
		perSegment: false,
		cost: 5,
		creditSpendType: CreditSpendType.AddNewPage
	},
	{
		id: 'exportVideoLandscape',
		icon: <CurrencyDollarIcon />,
		name: 'Export Video',
		perSegment: false,
		cost: 100,
		creditSpendType: CreditSpendType.VideoLandscape,
		originalCost: 200,  // Use originalCost in PaidActionButton to show strikethrough
	},
	{
		id: 'exportVideoPortrait',
		icon: <CurrencyDollarIcon />,
		name: 'Export Video',
		perSegment: false,
		cost: 100,
		creditSpendType: CreditSpendType.VideoPortrait,
		originalCost: 200,
	},
	{
		id: 'exportVideoLandscapeSubtitle',
		icon: <CurrencyDollarIcon />,
		name: 'Export Video',
		perSegment: false,
		cost: 100,
		creditSpendType: CreditSpendType.VideoLandscapeSubtitle,
		originalCost: 200,
	},
	{
		id: 'drawingMode',
		icon: <CurrencyDollarIcon />,
		name: 'Enter Drawing Mode',
		perSegment: false,
		cost: 20,
		creditSpendType: CreditSpendType.ImageScribble,
	},
	{
		id: 'enterContest',
		icon: <CurrencyDollarIcon />,
		name: 'Enter Contest',
		perSegment: false,
		cost: 10,
		creditSpendType: CreditSpendType.EnterContest
	},
	{
		id: 'WatermarkedStoryBookPDF',
		icon: <CurrencyDollarIcon />,
		name: 'Download Story PDF',
		perSegment: false,
		cost: 400,
		creditSpendType: CreditSpendType.WatermarkedStoryBookPDF
	},
	{
		id: 'OriginalStoryBookPDF',
		icon: <CurrencyDollarIcon />,
		name: 'Download Story PDF',
		perSegment: false,
		cost: 500,
		creditSpendType: CreditSpendType.OriginalStoryBookPDF
	},
];
import {
    buildCreateApi,
    coreModule,
    reactHooksModule,
} from "@reduxjs/toolkit/query/react";
// @ts-ignore
import { recorderControls } from "react-audio-voice-recorder/dist/hooks/useAudioRecorder";

 export const useAudioRecorderSafe = (
    require("react-audio-voice-recorder").useAudioRecorder
) as () => recorderControls;


export const createCompatApi = buildCreateApi(coreModule(), reactHooksModule({ unstable__sideEffectsInRender: true }))
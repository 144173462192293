import '@/styles/globals.css'
import type { AppProps } from 'next/app'
import { Provider } from "react-redux";
import { createStore } from "@/store/store";
import {env, envName} from "@/utils/env";
import { NextPage } from 'next';

import AppContainer from '@/layouts/app-container';
import { Auth0Provider, User } from "@auth0/auth0-react";
import { NextRouter, useRouter} from 'next/router';
import { Aleo, Poppins } from 'next/font/google'

import React from "react";

const aleo = Aleo({
  subsets: ['latin'],
  variable: '--font-aleo',
  weight: ["400", "700"],
})

const poppins = Poppins({
  subsets: ['latin'],
  variable: '--font-poppins',
  weight: ["400", "600", "700"],
})

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode
}

type TAppPropsWithLayout = AppProps<{}> & {
  Component: NextPageWithLayout
}


const onRedirectCallback = async (router: NextRouter | { pathname: string; query: { user?: string | undefined; state?: string | undefined; }; }[], appState?: any, user?: User) => {

  const userString = user ? encodeURIComponent(JSON.stringify(user)) : null;
  const stateString = appState ? encodeURIComponent(JSON.stringify(appState)) : null;

  await router.push({
    pathname: "/auth-return",
    query: { ...(stateString && { state: stateString }), ...(userString && { user: userString }) },
  })
};


export default function App({ Component, pageProps }: TAppPropsWithLayout) {
  const router = useRouter();
  const store = createStore();
  const getLayout = Component.getLayout ?? (page => page);

  return <Provider store={store}>
    <Auth0Provider
      domain={env("oauthDomain")}
      clientId={env("oauthClientId")}
      onRedirectCallback={(appState, user) => onRedirectCallback(router, appState, user)}
      redirectUri={env("location") + "/auth-return"}
      audience={env("oauthAudience")}>
      <div className={`font-poppins ${aleo.variable} ${poppins.variable}`}>
        <AppContainer>
          {getLayout(<Component {...pageProps} />)}
        </AppContainer>
      </div>
    </Auth0Provider>
  </Provider>
}

if (env("production")) {
  console.log = function () {};
}

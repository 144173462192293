import SideNav from "./side-nav";
import { FC, useEffect, useRef, useState } from "react";
import { ScrollDirection, useScrollDirection, useGetUser } from "@/utils/app-hooks";
import { Link as ScrollLink } from "react-scroll";
import { clamp } from "@/utils/dataUtils";
import { useAuth0 } from "@auth0/auth0-react";
import { env } from "@/utils/env";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { EllipsisVerticalIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { Menu, Transition } from "@headlessui/react";
import React from "react";
import { useGetTokenBalanceQuery } from "@/store/apis/user-api";
import { setTokenBalance } from "@/store/slices/tokenBalance";
import { openDialog } from '@/store/slices/dialog';
import Link from "next/link";
import Image from "next/image";
import { useRouter } from 'next/router';
import VerifyEmailModal from "@/components/modals/verify-email-modal";
import Modal, { CallerFn } from "@/components/modals/modal";
import StoryTransitionModal from "@/components/modals/story-transition-modal";

const TRANSITION_TIMING = "300ms";
const TRANSFORM_PATH_REGEX = /^\/(create-story.*)?$/;

export default function TopBar() {
    /* Redux Dispatch ----------------------------------------------------------- */
    const dispatch = useAppDispatch()
    const router = useRouter();
    const openDialogHandler = () => dispatch(openDialog({ variant: "add-balance" }));

    /* Redux Global State ------------------------------------------------------- */
    const { hasAuth } = useAppSelector(({ auth }) => auth);

    /* Fetch Token Balance ------------------------------------------------------ */
    const { data: tokenBalance } = useGetTokenBalanceQuery(
        {},
        { skip: !hasAuth },
    );

    /* Add Token Balance to Global Redux State ---------------------------------- */
    useEffect(() => {
        if (tokenBalance) {
            dispatch(setTokenBalance(tokenBalance.totalTokens));
        }
    }, [tokenBalance, dispatch]);

    const ref = useRef<HTMLDivElement>(null);
    const { loginWithRedirect } = useAuth0();
    const { asPath: pathname} = useRouter();

    const [user] = useGetUser();
    const [verifyEmailModal, setVerifyEmailModal] = useState<CallerFn>();
    const [transitionModal, setTransitionModal] = useState<CallerFn>();

    const scrollDir = useScrollDirection();
    const [height, setHeight] = useState(0);

    const [sideNavOpen, setSideNavOpen] = useState(false);
    const [useTransform, setUseTransform] = useState(false);
    const [transform, setTransform] = useState({
        transform: "translate(0,0)",
        "--transition-timing": useTransform ? TRANSITION_TIMING : "0s",
    });
    const [opacity, setOpacity] = useState(1);


    useEffect(() => {
        setHeight(ref.current?.getBoundingClientRect().height || 0);
    }, [ref.current]);

    useEffect(() => {
        setUseTransform(TRANSFORM_PATH_REGEX.test(pathname));
    }, [pathname]);

    useEffect(() => {
        if (!height) return;

        const onScroll = () => {
            const { scrollY } = window;
            const newOpacity = 1 - (height - scrollY) / height;
            setOpacity(clamp(newOpacity, 0, 1));
        };
        onScroll();

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [height]);

    useEffect(() => {
        let translate = -100;
        if (scrollDir !== ScrollDirection.Down || !useTransform) translate = 0;
        setTransform({
            transform: `translate(0,${translate}%)`,
            "--transition-timing": useTransform ? TRANSITION_TIMING : "0s",
        });
    }, [scrollDir, pathname]);

    const signInHandler = async () => await loginHandler('login');
    const signUpHandler = async () => await sighUpHandler("signup");

    const loginHandler = (screen_hint: "signup" | "login") =>
      loginWithRedirect({
          screen_hint,
          audience: env("oauthAudience"),
          appState: { returnTo: pathname || "/" },
      });

    const sighUpHandler = async (screen_hint: "signup" | "login") => {
        if (!transitionModal) return;
        try {
            await transitionModal();
            router.push("https://story.com/auth/login");
        } catch {  }
    }

    const showVerifyEmailModal = async () => {
        if (!verifyEmailModal || user?.emailVerified) return;
        try {
            await verifyEmailModal();
        } catch { }
    };

    return (
        <>
            <header ref={ref} className="top-bar" style={transform}>
                {useTransform ? (
                    <div
                        className="absolute inset-0 bg-secondary-900 -z-10 pointer-events-none"
                        style={{ opacity }}
                    />
                ) : null}

                <div className={`flex items-center mx-auto justify-between w-full max-w-screen-2xl`} style={{ opacity: 1 - opacity }}>
                    <LinkWrapper pathname={pathname}>
                        <div className="flex items-center gap-2 text-2xl sm:text-3xl font-aleo cursor-pointer">
                            <Image priority src="/assets/logos/storybird/logo.webp" width={64} height={64} alt="StoryBird logo" />
                            StoryBird.ai
                        </div>
                    </LinkWrapper>

                    <nav className="hidden lg:flex items-center gap-4">
                        {/*<Link*/}
                        {/*    href="/create-story"*/}
                        {/*    className="link-w-ul"*/}
                        {/*    aria-current={pathname.includes("/create-story") ? "page" : undefined}>*/}
                        {/*    Create*/}
                        {/*</Link>*/}
                        {/*<Link*/}
                        {/*    href="/library"*/}
                        {/*    className="link-w-ul"*/}
                        {/*    aria-current={pathname.includes("/library") ? "page" : undefined}>*/}
                        {/*    Library*/}
                        {/*</Link>*/}
                        {/*<Link*/}
                        {/*    href="/faqs"*/}
                        {/*    className="link-w-ul"*/}
                        {/*    aria-current={pathname.includes("/faqs") ? "page" : undefined}>*/}
                        {/*    FAQs*/}
                        {/*</Link>*/}
                        <Link
                            href="/blogs/story-transition"
                            className="link-w-ul"
                            aria-current={pathname.includes("/blogs") ? "page" : undefined}>
                            FAQs
                        </Link>
                        <Link
                            href={"https://story.com"}
                            target={"_blank"}
                            className="link-w-ul"
                        >
                            Story.com
                        </Link>
                        <div className="h-6 w-0.5 bg-white mx-2" />

                        {hasAuth ? (
                            <>
                                <Link
                                    href="/dashboard"
                                    aria-current={pathname.includes("/dashboard") ? "page" : undefined}
                                    className="flex gap-1 link-w-ul pb-0.5">
                                    <UserCircleIcon className="w-6 h-6" aria-hidden />
                                    Dashboard
                                </Link>
                                <Menu as="div" className="relative inline-block">
                                    <Menu.Button className="inline-flex items-center justify-between rounded-md py-2 px-3 min-w-[8rem] bg-neutral-800 bg-opacity-75 w-full">
                                        <div className="flex-grow flex items-center justify-center">
                                            <span className="text-sm font-medium tracking-wide text-neutral-50">
                                              ${tokenBalance ? (tokenBalance.totalTokens / 20).toFixed(2) : '0.00'}
                                            </span>
                                        </div>
                                        <EllipsisVerticalIcon className="w-6 h-6" aria-hidden />
                                    </Menu.Button>


                                    <Transition
                                        as={React.Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute top-0 right-0 mt-12 origin-top-right rounded-md bg-neutral-50 text-neutral-900 ui-open:shadow-md w-[20rem]">
                                            <Menu.Item>
                                                {({ close }) => (
                                                    <div className="p-6 flex flex-col gap-6">
                                                        {/* Token amount */}
                                                        <div className="space-y-2 text-center">
                                                            <p className="text-sm text-neutral-500">Balance</p>
                                                            <p className="text-3xl font-medium text-neutral-900">${tokenBalance ? (tokenBalance.totalTokens / 20).toFixed(2) : '0.00'}</p>
                                                        </div>
                                                        {/* CTAs */}
                                                        <div className="space-y-4">
                                                            <button className="bg-primary-400 text-white rounded-md w-full inline-flex justify-center tracking-wide text-sm py-2 px-3 hover:bg-primary-500" onClick={() => openDialogHandler()}>Add Balance</button>
                                                            <Link href="/dashboard/wallet" className="block hover:underline text-center" onClick={close}>History</Link>
                                                        </div>
                                                    </div>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </>
                        ) : (
                            <>
                                <button onClick={signInHandler} className="link-w-ul">
                                    Sign in
                                </button>
                                <button onClick={signUpHandler} className="btn-primary">
                                    Sign up
                                </button>
                            </>
                        )}
                    </nav>

                    <button
                        className="svg-btn lg:hidden"
                        aria-label={sideNavOpen ? "Close menu" : "Open menu"}
                        onClick={() => setSideNavOpen(true)}>
                        <Image src="/assets/icons/menu.svg" alt="" width={24} height={24} />
                    </button>
                </div>
            </header>
            <div className="verify-email-top-bar bg-[#D8795A] text-white text-center text-sm py-2 fixed w-full z-10" style={{ opacity: 1 - opacity }}>
                We've moved to <Link href={"https://www.story.com/"} target={"_blank"} className={"underline text-[#3A54CB]"}>Story.com</Link> - This site is live only for legacy user purchases, and will go down soon.
            </div>

            {
                user !== undefined && !user.emailVerified ? (
                  <div className="verify-email-top-bar bg-[#D8795A] text-white text-center py-2 fixed w-full z-10" style={{ opacity: 1 - opacity }}>
                      It looks like you haven't verified your email yet.
                      <button className="text-sm underline ml-2" onClick={showVerifyEmailModal}>
                          Verify Now
                      </button>
                  </div>
                ): null
            }

            <SideNav isOpen={sideNavOpen} close={() => setSideNavOpen(false)} />

            <Modal registerHandler={setVerifyEmailModal} content={VerifyEmailModal} />
            <Modal registerHandler={setTransitionModal} content={StoryTransitionModal} />
        </>
    );
}

const LinkWrapper: FC<{ children: JSX.Element, pathname: string }> = ({ children, pathname }) => {

    if (pathname === "/")
        return (
            <ScrollLink to="root" smooth>
                {children}
            </ScrollLink>
        );
    return <Link href="/">{children}</Link>;
};

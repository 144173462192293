import {
    CSSProperties,
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from "react";
import { useCanScroll } from "../../utils/app-hooks";

export type AnyFn = (...any: any[]) => void;
export type CallerFn = (...any: any[]) => Promise<any>;
type PromiseHandler = {
    resolve: AnyFn;
    reject: AnyFn;
};

export const MODAL_ANIM_TIMING = 300; // 300 milliseconds

export default function Modal({
    registerHandler,
    content: Content,
    contentProps,
    modalNoMaxWidth,
}: {
    registerHandler: Dispatch<SetStateAction<CallerFn | undefined>>;
    content: FC<PromiseHandler & any>;
    contentProps?: any;
    modalNoMaxWidth?: boolean;
}) {
    const [blockScroll, allowScroll] = useCanScroll();

    const [modalOpen, setModalOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [resolveHandler, setResolveHandler] = useState<AnyFn>();
    const [rejectHandler, setRejectHandler] = useState<AnyFn>();

    const closeModal = () => {
        setShowModal(false);
        allowScroll();
        setTimeout(() => setModalOpen(false), MODAL_ANIM_TIMING);
    };

    const [dynamicProps, setDynamicProps] = useState({});

    const openModal = useCallback((props = {}) => {
        setDynamicProps(props);
        const promise = new Promise<any>((res, rej) => {
            setResolveHandler(() => res);
            setRejectHandler(() => rej);
        });
        setModalOpen(true);
        blockScroll();
        setTimeout(() => setShowModal(true), 20);
        return promise;
    }, []);

    const failModal = useCallback(
        (...args: any[]) => {
            if (!rejectHandler) return;
            closeModal();
            rejectHandler(...args);
        },
        [rejectHandler]
    );

    const succeedModal = useCallback(
        (...args: any[]) => {
            if (!resolveHandler) return;
            closeModal();
            resolveHandler(...args);
        },
        [resolveHandler]
    );

    useEffect(() => {
        registerHandler(() => openModal);
    }, []);

    if (!modalOpen) return null;

    return (
        <div
            onClick={(e) => e.stopPropagation()}
            className="fixed grid place-items-center overflow-y-auto p-4 inset-0 z-40"
            style={
                {
                    "--animation-timing": `${MODAL_ANIM_TIMING}ms`,
                    pointerEvents: showModal ? "auto" : "none",
                } as CSSProperties
            }>
            <div className={`fixed flex-grow inset-0 bg-black h-full transition-opacity duration-300 ${showModal ? "opacity-60" : "opacity-0"}`}
            onClick={failModal} />

            <div
                className={`flex flex-col bg-background rounded-2xl transition-all duration-300 p-8 gap-2 w-full max-w-4xl ${showModal ? "opacity-1 translate-y-0" : "opacity-0 translate-y-1"}`}
                style={{ maxWidth: modalNoMaxWidth ? "auto" : "500px" } as CSSProperties}
            >
                {/* Close button */}
                <button
                    onClick={failModal}
                    className="absolute top-2 right-6 bg-transparent border-none text-[30px] cursor-pointer text-[#333] z-10 transform transition-colors duration-200 ease-in-out hover:scale-125 hover:text-[#555] focus:outline-none"
                    aria-label="Close Modal"
                >
                    &times;
                </button>

                <Content {...contentProps} {...dynamicProps} resolve={succeedModal} reject={failModal} />
            </div>
        </div>
    );

}

import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { StripeError } from "@stripe/stripe-js";
import { ChangeEvent } from "react";
import { imageStyleEnum } from "./store/apis/webstory-api";

export const BOOK_PRICE = 19;
export const AMAZON_BOOK_PRICE = 10;

export enum Gender {
  Male,
  Female,
  PreferNotToSay,
}

export const GENDER_ARRAY = Object.entries(Gender)
  .filter(([key]) => isNaN(Number(key)))
  .map(([key, value]) => ({
    key: key === "PreferNotToSay" ? "I'd prefer not to say" : key,
    value,
  }));

export type CardBrand =
  | "amex"
  | "cartes_bancaires"
  | "diners"
  | "discover"
  | "jcb"
  | "mastercard"
  | "visa"
  | "unionpay"
  | "none";

export interface PaginatedList<T> {
  items: T[];
  currentPage: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
  lastFetch: number;
}

export enum LibrarySortType {
  Latest,
  TotalLikes,
  OwnLiked,
}

export interface ContactUsFormParams {
  name: string;
  email: string;
  message: string;
}

export interface GetPageParams {
  currentPage: number;
  pageSize?: number;
}

export interface ApiError {
  status?: number;
  data: string | { message: string };
}

export type GeneralError = ApiError | FetchBaseQueryError | SerializedError | StripeError | { status: number, message: string };

export type InputChange = ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;

export interface TinyUser {
  id: string;
  name: string;
  created: string;
  lastName?: string;
  profileName?: string;
  bio?: string;
  profilePicture?: string;
}

export interface User extends TinyUser {
  profileName: string;
  profilePicture?: string;
  bio: string;
  email: string;
  phoneNumber: string;
  lastName: string;
  emailNotificationPreferences: any,
  dateOfBirth: string;
  internalUser: boolean;
  cardBrand: CardBrand;
  cardLast4: string;
  cardExpiryMonth: number;
  cardExpiryYear: number;
  isSubscribedToEmails: boolean;
  isSubscribedToSMS: boolean;
  emailVerified: boolean;
  bookOrdersCount: number;
  amazonPublishingCount: number;
  myItemsCount: number;
  totalUnclaimedRoyalties: number;
  totalProcessingRoyalties: number;
  voiceRecordingName: string;
  voiceRecordingPath: string;
  usedFreeAudioGeneration: boolean;
  kycVerified: kycStatus;
  address: string;
  country: string;
  photoIDType: string;
  photoIDNumber: string;
  created: string;
  defaultPublic: boolean;
}

export interface StoryRequest {
  id: string;
  childName: string;
  gender: Gender | null;
  childAge: string;
  topicOne: string;
  topicTwo: string;
  topicThree: string;
  locationName: string;
}

export interface StorySegment {
  created: string;
  imageRegenerating: boolean;
  index: number;
  textContent: string;
  imageKey: string;
  imageSeed: number | null;
  imageAltText: string | null;
  imagePrompt: string | null;
  maleAudioKey: string;
  maleAudioGenerating: boolean;
  portugueseAudioGenerating: boolean;
  portugueseAudioKey: string;
  femaleAudioKey: string;
  femaleAudioGenerating: boolean;
  customAudioKey: string;
  customAudioGenerating: boolean;
  imageStyle: imageStyleEnum;
}

export interface StoryContest {
  id: string;
  name: string;
  description: string;
  contestImage: string;
  isActive: boolean;
  startDate: string;
  endDate: string;
  numberOfStories: number;
  numberOfVotes: number;
}

export interface WebStory {
  id: string;
  topLevelCategory: string;
  slug: string;
  created: string;
  storyLikes: number;
  storyTitle: string;
  summary: string;
  storySegments: StorySegment[];
  user: TinyUser;
  shortId: string;
  storyPodcast: StoryPodcast;
  coverImage: string;
  storyDone: boolean;
  imagesDone: boolean;
  amazonBook: AmazonBook;
  hasMaleVoice: boolean;
  hasFemaleVoice: boolean;
  hasCustomVoice: boolean;
  hasPortugueseVoice: boolean;
  portugueseVoiceDone: boolean;
  maleVoiceDone: boolean;
  femaleVoiceDone: boolean;
  customVoiceDone: boolean;
  aiContributionRate: number;
  isPublic: boolean;
  storyComments: StoryComment[];
  storyContests: StoryContest[];
}

export interface StoryComment {
  commentText: string;
  created: string;
  id: string;
  profileName: string;
  profilePicture: string | null;
  userId: string;
  webStoryId: string;
}

export enum BookLifecycle {
  Requested,
  Validating,
  Publishing,
  Shipping,
  Cancelled,
  Error,
}

export enum PodcastLifecycle {
  Requested,
  Processing,
  Publishing,
  Error,
}

export enum VoiceType {
  GenericMale,
  GenericFemale,
  Portuguese,
  Custom,
  None
}

export enum PdfType {
  StoryBookPDF,
  EBookPDF,
}

export interface OwnWebStory extends WebStory, StoryRequest { }

export interface StoryPodcast {
  id: string;
  created: string;
  audioUploadUrl: string;
  finalAudioUrl: string;
  finalAudioDuration: number;
  appleLink: string;
  spotifyLink: string;
  podcastLifecycle: PodcastLifecycle;
  voiceType: VoiceType;
}

export interface AmazonBook {
  webStoryId: string;
  amazonUrl: string;
  amazonPublishLifecycle: AmazonPublishLifecycle;
  created: string;
}

export enum AmazonPublishLifecycle {
  None=-1,
  Requested,
  Processing,
  Published,
  Rejected,
  SelfPublished,
}

export interface BookOrderRequest {
  authorFirstName: string;
  authorLastName: string;
  email?: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  postal: string;
  state: string;
  shippingClassification: ShippingClassification | null;
  quantity: number;
  discountCode: DiscountCodeDetails | null;
}

export interface BookOrder extends BookOrderRequest {
  id: string;
  coverPDFUrl: string;
  gutsPDFUrl: string;
  phone: string;
  email: string;
  webStoryId: string;
  created: string;
  country: string;
  bookOrderLifecycle: BookOrderLifecycle;
  orderReference: string;
  orderPrice: number;
  shippingPrice: number;
  totalPrice: number;
  storyTitle: string;
  coverImage: string;
}

export enum BookOrderLifecycle {
  Requested,
  Generating,
  ReadyToSubmit,
  RPIReceived,
  RPIPrinting,
  RPIShipped,
  Delivered,
  Failed,
}

export enum ShippingClassification {
  priority,
  express,
  standard,
  economy,
}

export enum StoryReportReason {
  InappropriateText,
  NotLoading,
  HateSpeech,
  Other,
  InappropriateImages,
}

export enum CommentReportReason {
  InappropriateText,
  HateSpeech,
  Irrelevant,
  Other,
}

export interface DiscountCodeDetails {
  isValid: boolean;
  code: string;
  discountPercentage: number;
  discountCodeType: DiscountCodeType;
}

export enum DiscountCodeType {
  None,
  BookOrder,
}

export interface ShippingMethod {
  name: string;
  type: ShippingClassification;
  service: string;
  deliveryDays: number | [number, number];
}

export interface StoryInteractions {
  liked: boolean;
  reported: boolean;
}

export interface ReportStoryBody {
  storyReportReason: StoryReportReason;
  otherReason: string;
}

export interface ReportCommentBody {
  commentReportReason: CommentReportReason;
  otherReason: string;
}

export enum AmazonMarketplace {
  US,
  CA,
  MX,
  BR,
  DE,
  FR,
  IT,
  ES,
  AU,
  IN,
  JP,
  NL,
  UK,
}

export enum CreditSpendType {
  StoryGeneration,    // Entire Story Generation
  StorySegmentEdit,   // Edit a text segment
  AudioGeneration,    // Generate audio for entire story
  AudioRegeneration,  // Regenerate audio for a single segment
  ImageRegeneration,  // Regenerate image for a single segment
  AmazonPublishing,    // We publish to Amazon
  AmazonPublishingPDFOnly,   // Give Amazon PDF Only
  WatermarkedEBookPDF,     // Downloadable Watermarked PDF
  OriginalEBookPDF,        // Downloadable Original PDF
  RegenerateSegmentWithAI, // Regenerate a segment with AI
  AddNewPage,             // Add a new page to a story
  VideoLandscape,             // Downloadable Video Story book Landscape
  VideoPortrait,              // Downloadable Video Story book PDF Portrait
  VideoLandscapeSubtitle,      // Downloadable Video Landscape with Subtitles,
  ImageScribble, // Scribble Image
  EnterContest, // Enter a contest
  WatermarkedStoryBookPDF, // Downloadable Watermarked Story Book PDF
  OriginalStoryBookPDF, // Downloadable Original Story Book PDF
}

export enum StoryItemSubType
{
  Default,
  Male,
  Female,
  Portuguese,
  Custom
}

export const voiceToStoryItemSubType: { [key in VoiceType]?: StoryItemSubType } = {
  [VoiceType.GenericMale]: StoryItemSubType.Male,
  [VoiceType.GenericFemale]: StoryItemSubType.Female,
  [VoiceType.Portuguese]: StoryItemSubType.Portuguese,
  [VoiceType.Custom]: StoryItemSubType.Custom,
};

// Duplicated with a function; remove function in future and use mapping
export const storyItemSubTypeToText: Record<number, string> = {
  1: "Sir Richard (Male Voice)",
  2: "Fatima (Female Voice)",
  3: "Bruno (Portuguese Voice)",
  4: "Custom Voice",
}

export type NestedObj = { [key: string]: NestedObj };

export interface AmazonMetaData {
  title: string;
  subtitle: string;
  prefix: string;
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  summary: string;
  categories: object[] | undefined;
  seoKeywords: string;
  ageGroupMin: string;
  ageGroupMax: string;
  amazonMarketplace: AmazonMarketplace;
  AmazonPublishLifecycle: AmazonPublishLifecycle;
}

export interface amazonUserStatus {
  amazonPublishDate: string;
  amazonPublishLifecycle: number;
  amazonURL: string;
  amazonMarketplace: AmazonMarketplace;
  authorFirstName: string;
  authorLastName: string;
  coverImage: string;
  created: string;
  subtitle: string;
  title: string;
  webStoryId: string;
  topLevelCategory: string;
  slug: string;
  coverPDFUrl: string;
  gutsPDFUrl: string;
  summary?: string;
  categories?: object[];
  seoKeywords?: string;
  ageGroupMin?: string;
  ageGroupMax?: string;
  unclaimedRoyalties: number;
  lifetimeRoyalties: number;
}

export interface userItems {
  creditSpendType: number;
  created: string;
  storyTitle: string;
  topLevelCategory: string;
  slug: string;
  coverImage: string;
  webStoryId: string;
  itemUrl?: string;
  storyItemSubType: number;
}

export enum kycStatus {
  PendingVerification,
  Rejected,
  Completed,
}

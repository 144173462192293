
import { getCardExpiryDate, getCardNumberString, userHasCard } from "@/utils/dataUtils";

import { useGetUser } from "@/utils/app-hooks";

import { useRemoveCardMutation } from "@/store/apis/user-api";
import {useState} from "react";
import Link from "next/link";
import Image from "next/image";
import { useLogEvent } from "@/utils/analytics";

export default function PaymentCard({ editable }: { editable?: boolean }) {
    const [user] = useGetUser();
    const logEvent = useLogEvent();
    const [removeCard, { isLoading: removingCard, isError: removeCardError }] = useRemoveCardMutation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleRemoveCard = async () => {
        try {
            await removeCard().unwrap();
            // Handle success, maybe refetch the user data or update the local state
            toggleModal(); // Close the modal after successful removal
        } catch (error) {
            console.error("Failed to remove card:", error);
            // Handle error, maybe show a toast or error message
        }
    };


    if (!user || !userHasCard(user)) return null;

    return (
        <div className="flex p-5 gap-2 justify-between items-center min-w-max w-full max-w-sm bg-gold rounded-2xl">
            <div className="flex flex-col">
                <div className="flex items-center gap-4">
                    <p className="font-semibold text-xl capitalize">
                        {user.cardBrand.toString().replace(/_/g, " ")}
                    </p>
                    <p className="text-xs">
                        Exp.{" "}
                        {getCardExpiryDate({
                            cardExpiryMonth: user.cardExpiryMonth,
                            cardExpiryYear: user.cardExpiryYear,
                        })}
                    </p>
                </div>
                <p>{getCardNumberString(user.cardLast4)}</p>
            </div>
            {editable ? (
                <div className="flex gap-2">
                    <Link
                        href="/dashboard/change-payment"
                        className="svg-btn"
                        onClick={() => logEvent("edit_payment_card_clicked")}>
                        <Image src="/assets/icons/edit.svg" width={24} height={24} alt="" />
                    </Link>
                    <button onClick={toggleModal}>
                        <Image src="/assets/icons/x.svg" alt="" height={24} width={24} />
                    </button>
                </div>
            ) : null}

            {isModalOpen && (
                <div className="fixed grid place-items-center overflow-y-auto p-4 inset-0 z-40">
                    <div className="fixed flex-grow inset-0 bg-black opacity-60 h-full transition-opacity duration-300" onClick={toggleModal}></div>
                    <div className="flex flex-col bg-background rounded-2xl transform transition-opacity duration-300 p-8 opacity-1 translate-y-0">
                        <h4>Confirm Removal</h4>
                        <p>Are you sure you want to remove this card?</p>
                        <div className="flex justify-between mt-5">
                            <button className="bg-[#ccc] text-[#333] py-2 px-4 border-none rounded-md cursor-pointer transition-colors duration-200" onClick={toggleModal}>Cancel</button>
                            <button className="bg-[#e74c3c] text-[#fff] py-2 px-4 border-none rounded-md cursor-pointer transition-colors duration-200" onClick={handleRemoveCard}>Confirm</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

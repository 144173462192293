import auth from "./auth";
import { api } from "./apis/api";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import tokenBalance from "./slices/tokenBalance";
import dialog from "./slices/dialog";

const reducer = {
    auth,
    tokenBalance,
    dialog,
    [api.reducerPath]: api.reducer,
};

export const createStore = () => {
    const store = configureStore({
        reducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
    });
    setupListeners(store.dispatch);

    return store;
};

export type RootState = ReturnType<ReturnType<typeof createStore>["getState"]>;
export type AppDispatch = ReturnType<typeof createStore>["dispatch"];

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TokenBalanceState {
    totalTokens: number;
}

const initialState: TokenBalanceState = {
    totalTokens: 0,
};

export const tokenBalanceSlice = createSlice({
    name: "tokenBalance",
    initialState,
    reducers: {
        setTokenBalance: (state, { payload }: PayloadAction<number>) => {
            state.totalTokens = payload;
        },
    },
});

export const { setTokenBalance } = tokenBalanceSlice.actions;
export default tokenBalanceSlice.reducer;

import { useEffect, useState } from 'react';
import { env } from '@/utils/env';
import TopBar from '../components/nav/top-bar';
import { useAuth0 } from '@auth0/auth0-react';
import { enableAuth } from '@/store/auth';
import { useGetToken } from '@/utils/app-hooks';
import Maintenance from './maintenance';
import { Toaster } from 'react-hot-toast';
import { useAppDispatch, useAppSelector } from "@/store/store";
import { openDialog, closeDialog } from '@/store/slices/dialog';
import TokenDialog from '../components/dialogs/token-dialog';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useGetTokenBalanceQuery } from "@/store/apis/user-api";

export default function AppContainer({ children }: { children: React.ReactNode }) {
  const [noIndex] = useState(!env("production"));

  const [maintenanceMode] = useState(false); // Flip this manually to enable/disable maintenance mode

  const { isAuthenticated, isLoading } = useAuth0();
  const getToken = useGetToken();
  const dispatch = useAppDispatch();

  const dialogIsOpen = useAppSelector((state) => state.dialog.isOpen);
  const variant = useAppSelector((state) => state.dialog.variant);
  const closeDialogHandler = () => dispatch(closeDialog());
  const router = useRouter();

  useEffect(() => {
    (async () => {
      const token = await getToken();
      if (token) dispatch(enableAuth());
    })();
  }, [isAuthenticated, isLoading, dispatch, getToken]);

  // const { data: tokenBalance } = useGetTokenBalanceQuery(
  //     {},
  //     { skip: !isAuthenticated || isLoading },
  // );
  // const [hasShownTokenModal, setHasShownTokenModal] = useState(false);
  //
  // useEffect(() => {
  //     setHasShownTokenModal(false)
  // }, [router.pathname]);
  //
  // useEffect(() => {
  //     (async () => {
  //         const checkAndOpenModal = async () => {
  //             if (isLoading || !tokenBalance) return;
  //             if (!isAuthenticated) localStorage.removeItem('lastTokenModalTimestamp');
  //
  //             const now = new Date().getTime();
  //             const lastTokenModalTimestamp = localStorage.getItem('lastTokenModalTimestamp');
  //             const nextModalShowDelay = 60 * 60 * 1000 * 48; // 48hrs
  //
  //             if (isAuthenticated && !hasShownTokenModal && tokenBalance.totalTokens < 100) {
  //                 if (!lastTokenModalTimestamp || now - parseInt(lastTokenModalTimestamp) > nextModalShowDelay) {
  //                     const token = await getToken();
  //                     if (token) {
  //                         dispatch(enableAuth());
  //                         openDialogHandler();
  //                         setHasShownTokenModal(true);
  //                     }
  //                 }
  //             }
  //         };
  //
  //         await checkAndOpenModal();
  //     })()
  // }, [isAuthenticated, hasShownTokenModal, isLoading, getToken, tokenBalance]);

  return (
    <>
      <TokenDialog variant={variant} isOpen={dialogIsOpen} closeDialog={closeDialogHandler} />
      <Head>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="preconnect" href="https://ik.imagekit.io" />
        <meta charSet="utf-8" />
        <meta property="og:site_name" content="StoryBird.ai" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={env('location') + router.pathname} />
        <meta name="robots" content="noindex,nofollow" />
      </Head>
      <Script
        id="gtm-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': 
                              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
                              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                              })(window,document,'script','dataLayer','GTM-NH3X42H');`,
        }}
      />
      {maintenanceMode ? (
        <Maintenance />
      ) : (
        <>
          <Toaster
            position='top-center'
            reverseOrder={false}
          />
          <TopBar />
          <main className={`flex flex-1 `}>
            {children}
          </main>
        </>
      )}
    </>
  );
}

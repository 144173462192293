import { useAppSelector } from "@/store/store";
import { env } from "./env";

// Make sure less than 40 chars
export type AnalyticsEvent =
    | "contact_us_submit_clicked"
    | "contact_us_form_submitted"
    | "create_story_submit_clicked"
    | "create_story_form_submitted"
    | "txt_to_cartoons_video_play"
    | "load_more_book_orders_clicked"
    | "payment_method_updated"
    | "update_payment_submit_clicked"
    | "delete_story_modal_viewed"
    | "email_verified_modal_viewed"
    | "email_verified_error_modal_viewed"
    | "edit_name_submit_clicked"
    | "edit_name_form_submitted"
    | "edit_ph_number_submit_clicked"
    | "edit_ph_number_form_submitted"
    | "notification_prefs_submit_clicked"
    | "notification_prefs_form_submitted"
    | "edit_payment_card_clicked"
    | "load_more_library_stories_clicked"
    | "library_all_tab_clicked"
    | "library_top_picks_tab_clicked"
    | "library_liked_tab_clicked"
    | "book_order_payment_confirmed"
    | "book_order_payment_submitted"
    | "book_order_quantity_submitted"
    | "publish_on_amazon_clicked"
    | "landing_begin_story_clicked"
    | "add_audio_form_submitted"
    | "claim_story_modal_viewed"
    | "delete_story_submitted"
    | "delete_unclaimed_story_submitted"
    | "story_deleted_modal_viewed"
    | "delete_user_modal_viewed"
    | "delete_user_submitted"
    | "edit_blocked_modal_viewed"
    | "email_verification_resent"
    | "regenerate_img_modal_viewed"
    | "regenerate_img_error_modal_viewed"
    | "report_received_modal_viewed"
    | "report_story_modal_viewed"
    | "report_story_submitted"
    | "story_claimed_modal_viewed"
    | "verify_email_modal_viewed" // From here
    | "view_story_delete_clicked"
    | "view_story_claim_story_clicked"
    | "view_story_report_clicked"
    | "view_story_copy_url_clicked"
    | "view_story_share_fb_clicked"
    | "view_story_share_twitter_clicked"
    | "view_story_like_story_clicked"
    | "view_story_liked_story"
    | "view_story_unliked_story"
    | "view_story_order_book_clicked"
    | "view_story_amazon_publish_clicked"
    | "view_story_generate_audio_clicked"
    | "story_audio_play_clicked"
    | "story_audio_pause_clicked"
    | "story_audio_next_clicked"
    | "story_audio_prev_clicked"
    | "story_audio_restart_clicked"
    | "story_audio_voice_changed_to_male"
    | "story_audio_voice_changed_to_female"
    | "story_audio_voice_changed_to_custom"
    | "story_audio_voice_changed_to_portuguese"
    | "edit_story_title_clicked"
    | "edit_story_title_canceled"
    | "edit_story_title_save_clicked"
    | "edit_story_title_saved"
    | "remove_paragraph_modal_viewed"
    | "regenerate_text_modal_viewed"
    | "regenerate_audio_clicked"
    | "regenerate_audio_form_submitted"
    | "success_order_book"
    | "success_publish_book"
    | "unable_to_publish_modal_viewed"
    | "stripe_modal_loaded"
    | "stripe_modal_purchase_success"
    | "stripe_modal_purchase_failed"
    | "stripe_modal_insufficient_balance"
    | "regenerate_text_error_modal_viewed"
    | "drawing_mode_modal_viewed"
    | "repurchase_drawing_mode_modal_viewed"
    | "enter_contest_modal_viewed"
    | "stripe_modal_requires_action"
    ;

export const useLogEvent = () => {
    const { hasAuth } = useAppSelector(({ auth }) => auth);

    return (event: AnalyticsEvent, variables?: { [key: string]: any }) => {
        if (event.length >= 40) console.error("Analytics event name too long: ", event);
        if (!env("production")) return;
        const userType = (hasAuth ? "" : "un") + "registered";
        if (typeof window !== "undefined") {
            (window as any)?.dataLayer?.push({ event, userType, ...variables });
        }
    };
};
import { cloneElement, ReactElement } from 'react';
import { Denominations, PriceList } from './constants';

interface AvailableItem {
	id: string;
	name: string;
	icon: ReactElement;
	qty: number;
	perSegment: boolean;
}

interface DenominationDetails {
	name: string;
	tokens: number;
	costInUSD: number;
}

interface TokenRateCard {
	tokens: number;
	details: DenominationDetails;
	availableItems: AvailableItem[];
}

export default function useTokenRateCard(): TokenRateCard[] {
	return Denominations.map(denomination => {
		const availableItems = PriceList.reduce<AvailableItem[]>((items, item) => {
			if (denomination.tokens / item.cost > 0) {

				const itemWithSameNameAlreadyAdded = items.some(existingItem => existingItem.name === item.name);

				if (!itemWithSameNameAlreadyAdded || items.length === 0) {

					return [
						...items,
						{
							name: item.name,
							icon: cloneElement(item.icon, { className: 'w-4 h-4 inline mr-2' }),
							qty: Math.floor(denomination.tokens / item.cost),
							perSegment: item.perSegment,
							id: item.id,
						},
					];
				}
			}
			return items;
		}, []).filter(item => item.qty > 0);

		return { tokens: denomination.tokens, details: denomination, availableItems };
	});
}

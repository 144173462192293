import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
    token: string;
    hasAuth: boolean;
}

const initialState: AuthState = {
    token: "",
    hasAuth: false,
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setToken: (state, { payload }: PayloadAction<string>) => {
            state.token = payload;
        },
        enableAuth: (state) => {
            state.hasAuth = true;
        },
        clearAuthState: (state) => {
            Object.assign(state, initialState);
        },
    },
});

export const { setToken, enableAuth, clearAuthState } = authSlice.actions;
export default authSlice.reducer;

import { createCompatApi } from "@/utils/ssr-utils";
import { baseQuery } from "@/utils/store-utils";

export const api = createCompatApi({
    reducerPath: "api",
    baseQuery,
    tagTypes: [
        "User",
        "Lib",
        "Story",
        "UserLib",
        "UserStory",
        "BookOrders",
        "StoryInteractions",
        "Claim",
        "AmazonMeta",
        "AmazonPublishRequests",
        "StoryComments",
    ],
    endpoints: () => ({}),
});
